import React from "react";
import "./index.scss";
import Heading from '@hubins/components/Heading';
import { useEffect } from "react";
import { useState } from "react";
import { loadForm } from "../../helpers/form";

import CreateForm from "modules/CreateForm";
import { LoadingBox } from "@hubins/components/LoadingIndicators";
import { formDataToCollection } from '../../helpers/filters';
import SuccessModal from "./SuccessModal";
import RejectedModal from "./RejectedModal";

import __ from "localisation/hubins";
import __reg from 'localisation/hubins/registration';
import { useRef } from "react";

const useUpdateEffect = (effect, dependencies = []) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, dependencies);
};


const InvestmentForm = ({ selectedInvestment, handleUpdateInvestment, removeInvestment, juridical, endOfInvestments }) => {

  const [form, setForm] = useState(false);
  const [collection, setCollection] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState("");
  const [renderForm, setRenderForm] = useState(true);

  const formKey = () => {
    const asset = selectedInvestment.type === null ? 'n' : 'c';
    const formkey = juridical.concat(asset).concat('_tof');
    return formkey;
  };

  useUpdateEffect(() => {
    window.scrollTo(0, 1000);
    setRenderForm(true);
  }, [selectedInvestment]);

  useEffect(() => {
    loadForm(formKey(), "register", setForm, () => {
    });
  }, [selectedInvestment]);

  const validate = () => {
    // if current value is lower then 1 milion.
    if (Number(collection.current_value) < 1000000) {
      // Set Error messages...
      return false;
    }
    // if qualified shares answear is yes.
    if (collection.qualified_shares === "yes") {
      // Set Error messages...
      return false;
    }
    return true;
  };

  const handleValidated = (verified) => {
    const confModalStatus = verified ? 'success' : 'rejected';
    setConfirmationModalOpen(confModalStatus);
  };

  useUpdateEffect(() => {
    handleValidated(validate(collection));
  }, [collection]);

  const submit = (values) => {
    setCollection(formDataToCollection(values));
  };

  const resetForm = () => {
    setForm(false);
    setRenderForm(false);
  };

  const handleSuccess = () => {
    resetForm();
    handleUpdateInvestment(collection, form.key, form.type);
  };

  const handleRejected = () => {
    resetForm();
    removeInvestment();
  };

  return (
    <section className="section--medium">
      <Heading size="2" className="f-mono-weight-light">{__("tof_investment_form_title")}</Heading>
      <Heading size="5" className="c-neutral-5">{__("tof_investment_form_text")}</Heading>
      <Heading size="4" className="f-mono-weight-light">{selectedInvestment.name}</Heading>
      {form === false ? (
        <LoadingBox />
      ) : (
        <>
          {renderForm && (
            <CreateForm
              className="wrapper form wide"
              formData={form.content}
              submitType={submit}
              submitText={__reg("save_information")}
              loading={false}
              disabled={false}
            />
          )}
        </>
      )}
      <SuccessModal
        confirmationModalOpen = {confirmationModalOpen}
        setConfirmationModalOpen = {setConfirmationModalOpen}
        callback = {handleSuccess}
        endOfInvestments = {endOfInvestments}
      >
      </SuccessModal>
      <RejectedModal
        confirmationModalOpen = {confirmationModalOpen}
        setConfirmationModalOpen = {setConfirmationModalOpen}
        callback = {handleRejected}
      >
      </RejectedModal>
    </section>
  );
};

InvestmentForm.propTypes = {};

export default InvestmentForm;
