import React, { useEffect, useState } from 'react';
import AccountSelector from '@hubins/components/AccountSelector';
import { __route } from 'localisation';
import { useReportContext } from '@hubins/components/ReportContext';
import NoPortfolios from '../NoPortfolios';
import { Columns, Column } from 'components/Columns';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import Button from '@hubins/components/Button';
import Heading from '@hubins/components/Heading';
import Wrapper from '@hubins/components/Wrapper';
import middleman from 'helpers/middleman';
import TransactionCards from '@hubins/components/TransactionCards/TransactionCards';
import { Redirect } from 'react-router-dom';

const Init = () => {
  const {
    ready,
    portfolios,
    setPortfolioUuid,
    portfolioUuid,
    noPortfolios,
  } = useReportContext();

  const [transactions, setTransactions] = useState([]);
  const [transactionsLoading, setTransactionsLoading] = useState(false);


  const fetchTransactions = async () => {
    setTransactionsLoading(true);
    try {
      let response = await middleman.promisePost(`/transactions/show`, {
        status: 'draft',
        type: 'PRE',
        portfolioUuids: portfolios.map((p) => p.uuid),
      });
      // TODO: check if this is broken too
      // response.data.amount = (response.data.amount / 100);
      setTransactions(response.data);
    } catch (e) {
      console.error(e.message);
    }
    setTransactionsLoading(false);
  };

  useEffect(() => {
    if (ready && portfolios && portfolios.length > 0) {
      fetchTransactions();
    }
  }, [portfolios, ready]);

  if (noPortfolios) {
    return (
      <NoPortfolios />
    );
  }

  if (!ready) return <LoadingBox />;

  if (portfolios.length === 1) {
    return <Redirect to={`${__route("deposit")}/${portfolioUuid}`} />;
  }

  return (
    <Wrapper>
      <Heading>
        Välj konto
      </Heading>
      <Columns>
        <Column m="6" s="12">
          <AccountSelector
            portfolios={portfolios}
            setPortfolioUuid={setPortfolioUuid}
            portfolioUuid={portfolioUuid}
          />

          <Button
            href={`${__route("deposit")}/${portfolioUuid}`}
            size="large"
            icon="arrow right 1"
            className='cta s-top-xl'
            iconColor='white'
          >
            Gå vidare
          </Button>

          {transactionsLoading ? <LoadingBox /> : (
            <>
              {transactions && transactions.length > 0 && (
                <>
                  <Heading size="4" className="s-bottom-md s-top-xl">
                Påbörjade transaktioner
                  </Heading>
                  <TransactionCards
                    transactions={transactions}
                  />
                </>
              )}
            </>
          )}

        </Column>
        <Column m="6" s="12">
          <img src="/illustrations/premieinsattning.svg" alt="deposit" />
        </Column>
        <Column s="12">

        </Column>
      </Columns>
    </Wrapper>
  );

};

export default Init;
