import React, { Fragment } from 'react';
import __ from 'localisation';
// import { connect } from 'react-redux';

import Heading from '@hubins/components/Heading';
const mailto = `mailto:${__('platform_email')}?`;
const subject = `subject=Error%20on ${escape(window.location.href)}&`;
const version = `Version:%20${import.meta.env.MODE === 'beta' ? 'BETA%20-%20' : ''}v.${VITE_VERSION}%20-%20`;
const build = `Build:%20${VITE_TIMESTAMP}%0D%0A`;
const currentPage = `Current%20page:%20${escape(window.location.href)}%0D%0A`;
const userAgent = `User%20agent:%20${escape(navigator.userAgent)}%0D%0A`;
const browser = `Browser%20size:%20${window.innerWidth}%20x%20${window.innerHeight}%0D%0A-%0D%0A%0D%0A`;
const body = `body=-%20PLEASE%20INCLUDE%20THIS%20INFO%20-%0D%0A${version}${build}${currentPage}${userAgent}${browser}`;
const reportErrorContent = (
  <Fragment>
    <Heading size="3" className="sidebar__help-header">{__('need_help')}</Heading>
    <p>{__('contact_us_we_get_back')}</p>
    <br />
    <p>{__('email')}: <a className="link" target="_top" href={`mailto:${__('platform_email')}`}>{__('platform_email')}</a></p>
    <p>{__('tel')}: <a className="link" target="_top" href={`tel: ${__('platform_tel')}`}>{__('platform_tel')}</a></p>
    <a
      className="button small"
      href={`${mailto}${subject}${body}`} rel="noopener noreferrer" target="_blank">{__('report_error')}</a>
  </Fragment>
);

export default reportErrorContent;

// function mapStateToProps(state) {
//   return {
//     user: state.auth.User,
//   };
// }

// export default connect(
//   mapStateToProps,
//   false
// )(reportErrorContent);
