import React from "react";
import Button, { ButtonWrap } from "@hubins/components/Button";
import Heading from '@hubins/components/Heading';
import Modal from '@hubins/components/Modal';
import Icon from "@hubins/components/IconNew";

const InquiryModal = ({ inquiryModalOpen, setInquiryModalOpen }) => {

  return (
    <Modal
      className="animation-reveal narrow modal__overlay--centermobile"
      contentClass=""
      noWrapper={false}
      open={inquiryModalOpen}
      shouldCloseOnOverlayClick={false}
    >
      <Icon icon="Information Circle by Streamlinehq" size={16} className="s-top-md" />
      <Heading size="3" className="f-mono-weight-light s-top-md">
        Din förfrågan är nu inskickad till Hubins
      </Heading>
      <p>Vi kommer nu gå igenom uppgifterna du har lämnat till oss och kommer återkomma till dig inom x timmar med ett besked om vi kan ta emot din investering. </p>
      <p>Under tiden får du mer än gärna påbörja registreringen av en försäkringsansökan eller stänga ner och invänta att vi hör av oss. </p>
      <ButtonWrap>
        <Button
          className="spacing"
          href="/transfer/"
          onClick={() => {
            setInquiryModalOpen(!inquiryModalOpen);
          }}
        >
        Stäng
        </Button>
        <Button
          className="spacing cta"
          href="/tester/kunskapstest-start"
          onClick={() => {
            setInquiryModalOpen(!inquiryModalOpen);
          }}>
        Påbörja registering
        </Button>
      </ButtonWrap>
    </Modal>
  );
};

export default InquiryModal;
