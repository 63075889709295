import React, { useState, useEffect } from "react";
import __ from "localisation";
import Button, { ButtonWrap } from "@hubins/components/Button";
import Heading from '@hubins/components/Heading';
import { Columns } from "components/Columns";
import Main from "components/Main";
import Icon from "@hubins/components/IconNew";
import SecurityCard from "modules/SecurityCard";
import NewInvestmentModal from './NewInvestmentModal';
import JuridicalModal from "./JuridicalModal";
import middleman from '../../../server';
const AddInvestments = ({ investments, setInvestments, selectedInvestments, setSelectedInvestments, selectedTab, setSelectedTab, juridical, setJuridical }) => {

  const minInvestments = 3;

  const [namedInvestment, setNamedInvestment] = useState({
    investment: {
      value: true,
      error: false,
    }
  });

  const [newInvestmentModalOpen, setNewInvestmentModalOpen] = useState(false);
  const [juridicalModalOpen, setJuridicalModalOpen] = useState(false);
  const [visableInvestments, setVisableInvestments] = useState(minInvestments);

  const show = () => {
    return (visableInvestments === minInvestments) ? visableInvestments.length : minInvestments;
  };

  /* Dynamic add to state function
  *  params {item} takes a item to add to the state.
  *  params {state} takes a state which item should be added to.
  *  params {setter} takes a setter which adds item to the state.
  */

  const addToState = (item, state, setter) => {
    const copyState = [...state];
    copyState.unshift(item);
    setter(copyState);
  };

  /* Dynamic remove to state function
  *  params {item} takes a item to remove to the state.
  *  params {state} takes a state which item should be removed to.
  *  params {setter} takes a setter which removes item to the state.
  */

  const removeFromState = (index, state, setter) => {
    const copyState = [...state];
    copyState.splice(index, 1);
    setter(copyState);
  };

  /* Add investment to selectedInvestmets and removes from investments
  * uses the dynamic state setters
  * params {index} the index of investment i investments to be transfered
  * to selected investments.
  */

  const addToSelected = (index) => {
    const investment = investments[index];
    addToState(investment, selectedInvestments, setSelectedInvestments);
    removeFromState(index, investments, setInvestments);
  };

  /* Remove investment from selectedInvestmets and adds to investments if investment status is confirmed.
  * uses the dynamic state setters
  * params {index} the index of investment i selectedInvestments to be transfered
  * to investments.
  */

  const removeFromSelected = (index) => {
    const investment = selectedInvestments[index];
    if (investment.status === "confirmed") {
      addToState(selectedInvestments[index], investments, setInvestments);
    }
    removeFromState(index, selectedInvestments, setSelectedInvestments);
  };

  /* API call to middleman to get all securites that is confirmed by Hubins
  * and add them to investment state.
  * NOTE:
  * Hubins needs to give you guys a list of confirmed investments, so you can
  * add them to the database.
  */

  const getInvestments = async () => {
    const confirmedInvestments = await middleman.promiseGet('/securities/all')
      .then((res) => {
        setInvestments(res.data);
      })
      .catch((err) => console.log(err));
    return confirmedInvestments;
  };

  useEffect(() => {
    getInvestments();
  }, []);

  /* Opens a modal that forces the user to select juridical (private/company).
  */
  useEffect(() => {
    if (juridical === null) {
      setJuridicalModalOpen(!juridicalModalOpen);
    }
  }, [juridical]);

  return (
    <Main center={true}>
      <section className="section--medium wide s-top-lg">
        <Heading size="2" className="f-mono-weight-light">
          {__("tof_add_investment_title")}
        </Heading>
        <Heading size="4" className="c-neutral-6 f-mono-weight-light s-top-m">
          {__("tof_add_investment_confimed_title")}
        </Heading>
        <Heading size="5" className="c-neutral-5">
          {__("tof_add_investment_confimed_text")}
        </Heading>
        <Columns className="columns--tight s-top-m">
          <SecurityCard
            name="Ny investering"
            type=""
            original
            callback={() => setNewInvestmentModalOpen(!newInvestmentModalOpen)}
          ></SecurityCard>
          {investments
            ? investments.slice(0, visableInvestments).map((investment, index) => (
              <SecurityCard
                key={index}
                name={investment.name}
                type={investment.type}
                callback={() => addToSelected(index)
                }
              ></SecurityCard>
            ))
            : null}
        </Columns>
        <ButtonWrap center>
          <Button
            className="responsive spacing withicon"
            onClick={() => {
              setVisableInvestments(show());
            }}
          >
            {(show() <= minInvestments)
              ? <>{__("tof_add_investment_confimed_show_less")}<Icon
                anim=""
                ariaHidden={undefined}
                className=""
                fallback="fallback"
                icon="arrow up 1"
                size={16}
                spacing="" /></>
              : <>{__("tof_add_investment_confimed_show_more")}<Icon
                anim=""
                ariaHidden={undefined}
                className=""
                fallback="fallback"
                icon="arrow down 1"
                size={16}
                spacing="" /></>}
          </Button>
        </ButtonWrap>
      </section>
      <section className="section--medium wide s-top-lg">
        <Heading size="4" className="c-neutral-6 f-mono-weight-light">
          {__("tof_add_investment_added_investments_title")}
        </Heading>
        <Heading size="5" className="c-neutral-5">
          {__("tof_add_investment_added_investments_text")}
        </Heading>
        <Columns className="columns--tight s-top-m">
          {selectedInvestments
            ? selectedInvestments.map((investment, index) => (
              <SecurityCard
                key={index}
                name={investment.name}
                type={investment.type}
                closable={() => removeFromSelected(index)}
              ></SecurityCard>
            ))
            : null}
        </Columns>
      </section>
      <section className="section--medium s-top-lg">
        <Button
          className="spacing cta"
          disabled={(selectedInvestments.length < 1)}
          onClick={() => {
            setSelectedTab(selectedTab + 1);
          }}
        >
          Gå vidare
        </Button>
      </section>

      <NewInvestmentModal
        newInvestmentModalOpen={newInvestmentModalOpen}
        setNewInvestmentModalOpen={setNewInvestmentModalOpen}
        selectedInvestments={selectedInvestments}
        setSelectedInvestments={setSelectedInvestments}
        setNamedInvestment={setNamedInvestment}
        namedInvestment={namedInvestment}
        addToState={addToState}
      ></NewInvestmentModal>

      <JuridicalModal
        juridicalModalOpen={juridicalModalOpen}
        setJuridicalModalOpen={setJuridicalModalOpen}
        setJuridical={setJuridical}
      >
      </JuridicalModal>
    </Main>
  );
};

AddInvestments.propTypes = {};

export default AddInvestments;
