import React, { useEffect, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import IconNew from '../IconNew';
import LoadingBar from '@hubins/components/LoadingIndicators';
import { getPortfolioAlert, ALERT_TAGS } from 'helpers/portfolio';
import Modal from '@hubins/components/Modal';
import FormInfo from '@hubins/components/FormInfo';
import TermsModal from './TermsModal';

import './index.scss';

interface AccountSelectorProps {
  portfolios: any[];
  portfolioUuid: string;
  setPortfolioUuid: (uuid: string) => void;
}

interface AccountProps {
  portfolio: any;
  portfolioUuid: string;
  handleChange: (e) => void;
  alerts: any;
}

const Account = ({portfolio, portfolioUuid, handleChange, alerts}: AccountProps) => {
  const selected = portfolioUuid === portfolio.uuid;
  const alert = alerts && alerts.length > 0 ? alerts[0] : false;
  return (
    <label key={portfolio.uuid} className={`accountselector__label ${selected ? 'selected' : ''} ${alert ? 'acc-alert' : ''}`}>
      <input
        className='accountselector__input'
        type="radio"
        name="portfolio"
        value={portfolio.uuid}
        checked={selected}
        onChange={handleChange}
      />
      <div className="accountselector__logo">
        KF
      </div>
      <div>
        <div className="accountselector__name">
          {portfolio.policy_holder.length > 0 ? portfolio.policy_holder[0].name : 'Unknown'}
        </div>
        <div className="accountselector__short">
          {portfolio.short_name}
        </div>
      </div>
      {alert ? (
        <div className={`accountselector__status`}>
          <IconNew icon="alert circle" className='acc-alert' size={24} />
        </div>
      ) : (
          <div className={`accountselector__status ${selected ? 'selected' : ''}`}>
            <IconNew icon="check signle" size={8} color='primary' />
          </div>
      )}

    </label>
  )
}



const getAlerts = (portfolios) => {
  if (!portfolios) return {};
  return portfolios.reduce((carry, portfolio) => {
    const tags = portfolio.tags;
    if (tags) {
      const tagNames = tags.map((tag) => tag.name);
      const foundAlerts = ALERT_TAGS.filter((tag) => tagNames.includes(tag));
      carry[portfolio.uuid] = foundAlerts;
      return carry;
    }
  }, {});
}

const AccountSelector = ({portfolios, portfolioUuid, setPortfolioUuid}: AccountSelectorProps) => {

  const [selectedPortfolio, setSelectedPortfolio] = useState<any>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => { setIsExpanded(!isExpanded); };
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [portfolioNotification, setPortfolioNotification] = useState<any>(null);
  const [showPortfolioNotification, setShowPortfolioNotification] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false); // Make this more dynamic if more modals are needed in the future
  const [alerts, setAlerts] = useState({});

  const singlePortfolio = portfolios && portfolios.length === 1;

  useEffect(() => {
    setAlerts(getAlerts(portfolios))
  }, [portfolios]);

  useEffect(() => {
    if (portfolioUuid && portfolios.length > 0) {
      const portfolio = portfolios.find((portfolio) => portfolio.uuid === portfolioUuid);
      if (portfolio) {
        setSelectedPortfolio(portfolio);
      }
    }
  }, [portfolioUuid, portfolios])

  const closePortfolioNotification = (e) => {
    e.preventDefault();
    setShowPortfolioNotification(false);
  }

  useEffect(() => {
    if (selectedPortfolio) {
      const uuid = selectedPortfolio.uuid;
      if (alerts[uuid] && alerts[uuid].length > 0) {
        const alertTag = alerts[uuid][0];
        setPortfolioNotification(getPortfolioAlert(alertTag, selectedPortfolio, closePortfolioNotification, setShowTermsModal));
        const sessionKey = `${uuid}-${alertTag}`;
        const sessionItem = sessionStorage.getItem(sessionKey);
        if (!sessionItem) {
          sessionStorage.setItem(sessionKey, 'true');
          setShowPortfolioNotification(true);
        }
      } else {
        setShowPortfolioNotification(false);
        setPortfolioNotification(null);
      }
    }
  }, [selectedPortfolio])

  const handleChange = (e) => {
    setPortfolioUuid(e.target.value);
    setIsExpanded(false);
  }
  return (
    <>
        <TermsModal setOpen={setShowTermsModal} open={showTermsModal} portfolio={selectedPortfolio} setAlerts={setAlerts} />
        <Modal
          open={!!portfolioNotification && showPortfolioNotification}
          className="accountselector__modal"
        >
          {portfolioNotification && portfolioNotification}
        </Modal>
        <button className="accountselector" {...getToggleProps({ onClick: toggleExpand, disabled: !(!!selectedPortfolio) })}>
        <div className={`accountselector__label ${isExpanded ? 'accountselector__selected' : ''}`}>
          <div className="accountselector__logo">
            KF
          </div>
          <div className={`accountselector__wrapper ${!!selectedPortfolio ? '' : 'loading'}`}>
            {selectedPortfolio ? (
              <>
                <div className="accountselector__name">
                  {selectedPortfolio.policy_holder.length > 0 ? selectedPortfolio.policy_holder[0].name : 'Unknown'}
                </div>
                <div className="accountselector__short">
                  {selectedPortfolio.short_name}
                </div>
              </>
            ) : (
                <LoadingBar large />
            )}

          </div>
          {(selectedPortfolio && !singlePortfolio) && (
            <div
              className={`accountselector__expander ${isExpanded ? 'expanded' : ''}`}
            >
              <IconNew icon="arrow down" size={8} />
            </div>
          )}
        </div>
        <div className='accountselector__portfolios' {...getCollapseProps()}>
          {portfolios.filter(portfolio => (portfolio.uuid !== portfolioUuid)).map((portfolio) => {
            return (
              <Account
                key={portfolio.uuid}
                portfolio={portfolio}
                portfolioUuid={portfolioUuid}
                handleChange={handleChange}
                alerts={alerts[portfolio.uuid]}
                // setPortfolioNotification={setPortfolioNotification}
              />
            )
          })}
        </div>
      </button>
      {(selectedPortfolio && alerts[portfolioUuid] && alerts[portfolioUuid].length > 0) && (
        <FormInfo
          className='accountselector__notification'
          color="info"
          text={portfolioNotification}
        />
      )}
    </>
  )

};

export default AccountSelector;