import React from 'react';
import Heading from '@hubins/components/Heading';
import Button, { ButtonWrap } from '@hubins/components/Button';
import { Columns, Column } from 'components/Columns';
import './index.scss';

const securityId = process.env.NODE_ENV === 'production' ? '1243' : '935';

const cooperations = [
  {
    type: 'Private equity',
    title: 'Royc',
    text: 'Minsta investering 1 000 000 SEK',
    image: '/illustrations/royc.png',
    alt: 'Royc',
    href: '/royc/register',
    caseHref: `/produkt/${securityId}`
  }
];

const CooperationCard = ({ type, title, image, alt, href, text, caseHref }) => {
  return (
    <div className="cooperation-card">
      <p className='c-accent'>{type}</p>
      <Heading size="3" className="s-top-m">
        {title}
      </Heading>
      <p>{text}</p>
      <div className='cooperation-card__image'>
        <img src={image} alt={alt} />
      </div>
      <ButtonWrap>
        <Button className='cta large' icon='arrow right 1' iconColor='white' href={href}>
          Skapa konto
        </Button>
        <Button icon="arrow right 1" className="white large" href={caseHref}>
          Läs mer
        </Button>
      </ButtonWrap>
    </div>
  )
};

const CooperationCards = () => {

  return (
    <>
      <Heading size="3" className="s-top-xl s-bottom-xl">
        Aktuella samarbeten
      </Heading>
      <Columns>
        {cooperations.map((cooperation) => (
          <Column m="6" l="4" key={cooperation.title}>
              <CooperationCard {...cooperation} />
          </Column>
        ))}
      </Columns>
    </>
  );
};

export default CooperationCards;
