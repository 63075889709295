import React, { useEffect, useState } from 'react';
import Main from "components/Main";
import Heading from '@hubins/components/Heading';
import Wrapper from "@hubins/components/Wrapper";
import Icon from "@hubins/components/IconNew";
import Button, { ButtonWrap } from '@hubins/components/Button';
import { getMoney } from 'helpers';
import TransferResult from './TransferResult';
import { __route } from 'localisation';

import api from "api-connector";
// import apienv from "server/apienv";
import apienv from '../../server/apienv';
const TransferReports = () => {

  const baseUrl = apienv.base_url;
  const middleman = api(baseUrl);

  const [holdings, setHoldings] = useState(false);
  const [total, setTotal] = useState(0);

  const increament = (value) => {
    setTotal(prevTotal => prevTotal + value);
  };

  const gains = () => {
    if (total > 0) {
      return "tjäna";
    }
    if (total <= 0) {
      return "förlora";
    }
  };

  const getHoldings = async () => {
    const response = await middleman.get(`/holdings`);
    setHoldings(response.data);
  };

  useEffect(() => {
    getHoldings();
  }, []);


  return (
    <Main className="white">
      <Wrapper narrow noBg>
        <section className="section--medium wide s-top-lg padding">
          <Heading size="1" className="c-primary">
            Din rapport
          </Heading>
        </section>
        <hr></hr>
        {holdings
          ? holdings.map((holding, index) => (
            <TransferResult
              key={index}
              holding={holding}
              callback={increament}
            ></TransferResult>
          ))
          : null}
        <hr></hr>
        <Wrapper noBg>
          <Heading size="4" className="f-mono-weight-light">
            Totalt skulle du {gains()} <span className="c-primary">{getMoney(total, "kr", 0)}</span> på att flytta in alla dina innehav till oss.
          </Heading>
        </Wrapper>
        <ButtonWrap>
          <Button
            className="spacing withicon cta"
            href={__route('transfer_info')}
            small
          >
            Skicka in information
            <Icon
              anim=""
              ariaHidden={undefined}
              className=""
              color=""
              fallback="fallback"
              icon="arrow right 1"
              size={16}
              spacing=""
            />
          </Button>
          <Button
            className="spacing withicon"
            href={__route('transfer')}
            small
          >
            Till start
            <Icon
              anim=""
              ariaHidden={undefined}
              className=""
              color=""
              fallback="fallback"
              icon="arrow right 1"
              size={16}
              spacing=""
            />
          </Button>
        </ButtonWrap>
      </Wrapper>
    </Main>
  );
};

export default TransferReports;
