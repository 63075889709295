import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCollapse } from 'react-collapsed';
import Icon, { IconSizes } from '@hubins/components/IconNew';

import './index.scss';

type Props = {
  defaultOpen?: boolean,
  buttonStyle?: string,
  buttonNoStyle?: boolean,
  iconSize?: IconSizes,
  label: string,
  className?: string,
  children: React.ReactNode,
}

const Accordeon = ({ defaultOpen, buttonStyle = '', buttonNoStyle, iconSize = 16, label, className = '', children }: Props) => {

  const [ isExpanded, setExpanded ] = useState(defaultOpen);
  const handleClick = () => {setExpanded(!isExpanded);};
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <>
      <button
        {...getToggleProps({ onClick: handleClick })}
        className={'Accordeon ' + (buttonNoStyle ? buttonStyle : `button button--accordeon large withicon ${buttonStyle}`)}
      >
        {label}
        <Icon
          icon="arrow down 1"
          size={iconSize}
          ariaHidden
          anim={isExpanded ? 'active rotate-cw-180' : ''}
        />
      </button>
      <div {...getCollapseProps()}>
        <div className={className}>
          {children}
        </div>
      </div>
    </>
  );
};

Accordeon.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  buttonStyle: PropTypes.string,
  buttonNoStyle: PropTypes.bool,
};

export default Accordeon;
