import React, { useEffect, useState } from 'react';
import __ from 'localisation';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import { marketBanks } from 'helpers/bankList';
import { Columns, Column } from 'components/Columns';
import SimpleTable from 'modules/Tables/SimpleTable';
import { getMoney } from 'helpers';
import Heading from '@hubins/components/Heading';
import middleman from 'helpers/middleman';
import Wrapper from '@hubins/components/Wrapper';

// Supports SE, FI, NO as market
const banks = marketBanks['SE'];

const DepositInstructions = ({ transactionUuid }) => {
  const [instructions, setInstructions] = useState(false);

  const init = async () => {
    const response = await middleman.promiseGet(`/transactions/${transactionUuid}/instructions`);
    setInstructions(response.data);
  };

  useEffect(() => {
    init();
  }, []);

  if (instructions === false) return <LoadingBox />;

  const headers = [
    {
      Header: '',
      accessor: 'text',
      Footer: __('payment_amount')
    },
    {
      Header: '',
      accessor: 'value',
      Footer: getMoney((instructions.amount / 100), instructions.currency)
    },
  ];

  const data = [
    {
      text: __('bankgiro_number'),
      value: '556-3614'
    },
    {
      text: __('payment_reference'),
      value: instructions.reference
    },
    {
      text: __('payment_receiver'),
      value: 'Quantum Leben AG'
    }
  ];

  return (
    <>
      <Wrapper stretch className="basic-padding-large">
        <Columns>
          <Column s="12">
            <Heading size="4">
              {__('deposit_instructions_title')}
            </Heading>
            <p>
              {__('deposit_instructions_text')}
            </p>
          </Column>
          <Column s="12">
            <SimpleTable
              className="maxwidth"
              data={data}
              headers={headers}
            />
          </Column>
        </Columns>
        <Columns className="neutral-2 box-padding s-top-xl">
          <Column s="12">
            <Heading element="h3" size="4">{__('list_of_banks_text')}</Heading>
          </Column>
          {banks.map((obj, index) => (
            <Column xs="6" m="3" key={index} box bg="white" className="overflow-hidden">
              <div className="bank-container">
                <a href={obj.link} target="_blank" rel="noopener noreferrer">
                  <img src={obj.path} alt={obj.name} />
                </a>
              </div>
            </Column>
          ))}
        </Columns>
      </Wrapper>


    </>
  );

};

export default DepositInstructions;
