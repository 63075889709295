import React, { useState, useEffect } from 'react';
import __, { __route } from 'localisation';
import Wrapper from '@hubins/components/Wrapper';
import CreateForm from 'modules/CreateForm';
import { loadForm, createAnswer } from 'helpers/form';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import { Redirect } from "react-router-dom";

const InvestDisclaimer = ({ private_persons }) => {

  const [form, setForm] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadForm('invest_disclaimer', 'register', false, false, setForm);
  }, []);

  const submit = async (values) => {
    try {
      // const profile = private_persons[0];

      setError(false);
      const body = {
        key: form.key,
        type: form.type,
        version: form.version,
        data: values,
        // profileUuid: profile.uuid,
      };
      setLoading(true);
      await createAnswer(body);
      setRedirect(true);
    } catch {
      setLoading(false);
      setError(__('common_error_validation'));
    }

  };

  if (!form) return <LoadingBox />;

  if (redirect) return <Redirect to={__route('invest')} />;

  return (
    <Wrapper stretch className="primary">
      <CreateForm
        className="primary"
        formData={form.content}
        submitText={"Till Investeringar"}
        buttonProps={
          {
            icon: 'arrow right 1',
            iconColor: 'primary',
            className: 'white'
          }
        }
        submitType={submit}
        error={error}
        loading={loading}
        disabled={loading}
      />
    </Wrapper>
  );

};

export default InvestDisclaimer;
