import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import Main from "components/Main";
import Heading from '@hubins/components/Heading';
import Wrapper from "@hubins/components/Wrapper";
import TransferExtraInfo from 'modules/TransferExtraInfo';

import middleman from 'helpers/middleman';

const TransferInfo = () => {
  const location = useLocation();

  const [holdings, setHoldings] = useState();
  const [loading, setLoading] = useState(true);

  const getHoldings = async () => {
    const response = await middleman.get(`/holdings`);
    let result = null;
    if (location.state !== undefined) {
      result = response.data.filter(holding => holding.uuid === location.state.uuid);
    }
    else {
      result = response.data.filter(holding => holding.status === "init");
    }
    setHoldings(result);
    setLoading(false);
  };

  useEffect(() => {
    getHoldings();
  }, []);

  return (
    <Main center={true} className="white">
      <Wrapper narrow tight noBg>
        <section className="section--medium wide s-top-lg">
          <Heading size="1" className="c-primary">
            Skicka in information
          </Heading>
          <Heading size="4" className="c-neutral-6 f-mono-weight-light s-top-m">
            Curabitur blandit tempus porttitor. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
          </Heading>
        </section>
      </Wrapper>
      {!loading && <TransferExtraInfo
        middleman={middleman}
        holdings={holdings}
      />
      }
    </Main>
  );
};

export default TransferInfo;
