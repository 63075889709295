import React, { useEffect, useState, useRef, useCallback } from 'react';
import __, { __route } from 'localisation/hubins';
import __reg from 'localisation/hubins/registration';
import CreateForm from "modules/CreateForm";
import { Columns, Column } from 'components/Columns';
import { loadForm } from 'helpers/form';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import Heading from '@hubins/components/Heading';
import Modal from '@hubins/components/Modal';
import Input from '@hubins/components/Input';
import { swedishSsn } from "components/Validation";
import { sanitizeSsn } from "helpers";
import BankID from "modules/BankID";
import BankIDV6 from "modules/BankID/BankIDV6";
import { useFeature } from '@growthbook/growthbook-react';
import { bypass } from "states/utilities";
import { connect } from 'react-redux';
import { authOperations } from '../../state/ducks/auth';
import Button from '@hubins/components/Button';

const Terms = ({
  submitUser,
  registeredUser,
  authToken,
  setView,
  setFormResponse,
}) => {
  const [form, setForm] = useState(false);
  const [ssn, setSsn] = useState('');
  const [ssnError, setSsnError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankid, setBankid] = useState(false);
  const [modal, setModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const subRef = useRef(null);

  const BANKID_V6 = useFeature('bankid-api-v6').on;
  const BankIDComponent = BANKID_V6 ? BankIDV6 : BankID;

  useEffect(() => {
    loadForm('gdpr_terms', 'register', null, null, setForm);
  }, []);

  const submit = async (values) => {

    if (ssnError || !ssn) {
      return;
    }

    setFormResponse({
      data: values,
      key: form.key,
      type: form.type,
      version: form.version,
    });
    setFormSubmitted(true);
    setBankid(true);
    setLoading(true);
  };

  const bankIdCallback = (response) => {
    submitUser(response);
  };

  const handleAbort = () => {
    setLoading(false);
    setBankid(false);
  };

  const externalSubmit = () => {
    subRef.current.click();
  };

  const sanitizeOnBlur = useCallback((e) => {
    const value = sanitizeSsn(e.target.value);
    setSsn(value);
    setSsnError(swedishSsn(value));
  }, [setSsn, setSsnError]);

  useEffect(() => {
    if (authToken && formSubmitted) {
      if (registeredUser) {
        setModal(true);
        setBankid(false);
      } else {
        setView('roaring');
      }
    }
  }, [authToken, registeredUser, formSubmitted]);

  return (
    <Columns>
      <Modal
        open={modal}
        closeButton={import.meta.env.VITE_MODE === 'development'}
        callback={() => {
          import.meta.env.VITE_MODE === 'development' && setModal(false);
        }}
        className="flex-center"
      >
        <Heading
          element="h3"
          size={3}
        >
          {__('registered_user_title')}
        </Heading>
        <p>
          {__('registered_user_text')}
        </p>
        <div style={{ padding: "20px 0 0" }}>
          <Button href={__route('root')}>
            {__('my_pages')}
          </Button>
        </div>
      </Modal>
      {bankid && (
        <>
          <BankIDComponent
            initBody={{
              ssn,
              isTest: bypass(),
              process: 'register',
            }}
            hubins
            initEndpoint="/bauth/init"
            statusEndpoint={BANKID_V6 ? "/bauth/qrcode" : "/bauth/status"}
            callback={bankIdCallback}
            abortCallback={handleAbort}
            useCsrf
          />
        </>
      )}
      <Column m="6">
        <div className='basic-padding-small'>
          <Heading size="2">
          Välkommen till Hubins
          </Heading>
          <Heading size="4">
          Skapa konto snabbt, tryggt och gratis!
          </Heading>
          <Heading size="3" className="s-top-xxl">
            Det här får du tillgång till
          </Heading>
          <ul className='s-top-l'>
            <li>
              Investeringssida med exklusiva investeringar
            </li>
            <li>
              Kapitalförsäkring för alla investeringar - Privat och bolag
            </li>
            <li>
              Flytta in och samla investeringar
            </li>
          </ul>
        </div>
        {form ? (
          <>
            <CreateForm
              formData={form.content}
              submitType={submit}
              submitText={__('accept_and_proceed')}
              // buttonProps={{ icon: 'save' }}
              submitRef={subRef}
              buttonStyle={"hidden"}
              loading={loading}
              disabled={loading}
            />

            <div className='basic-padding-small'>
              <Input
                id="ssn"
                placeholder={__('ssn_placeholder')}
                required
                type="tel"
                label={__('enter_your_ssn')}
                controlled
                onBlur={sanitizeOnBlur}
                error={ssnError}
                value={ssn}
                className="s-bottom-s"
                callback={(value) => {
                  setSsn(value);
                  setSsnError(swedishSsn(value));
                }}
              />
              <Button
                size="large"
                icon="bankid"
                iconSize={16}
                iconColor="white"
                label="BankID"
                className='cta s-top-xl'
                onClick={externalSubmit}
              >
                {__reg('accept_and_signin')}
              </Button>
            </div>
          </>
        ) : (
          <LoadingBox />
        )}
      </Column>
      <Column m="6">
        <img src="/illustrations/Kunduppgifter.svg" alt="Kunduppgifter" />
      </Column>
    </Columns>
  );

};

function mapStateToProps(state) {
  return {
    registeredUser: state.auth.User.registeredUser,
    authToken: state.auth.User.authToken,
  };
}

const mapDispatchToProps = {
  submitUser: authOperations.submitUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Terms);
