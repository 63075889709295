import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@hubins/components/Input';
import ErrorMessage from 'components/ErrorMessage';
import Button from '@hubins/components/Button';
import { sanitizeSsn } from 'helpers';
import __ from 'localisation/hubins/registration';
import __ui from 'localisation';
import Heading from '@hubins/components/Heading';
import { useFakeRoaring } from 'states/utilities';
import { orgNumber } from 'components/Validation';
import middleman from 'helpers/middleman';


export const getRoaringData = async (orgNr, setData = false, setLoading = false, setError = false, minBeneficials = 4) => {
  // Reset all states
  setData && setData(false);
  setLoading && setLoading(true);
  setError && setError(false);

  // Fetch company roaring data
  let formatedRoaringData = false;

  const body = {
    isTest: useFakeRoaring()
  };

  await middleman.promisePost(`/roaring/org/start/${sanitizeSsn(orgNr)}`, body)
    .then(response => {
      setLoading && setLoading(false);
      // Handling error responses
      if (response.status !== 200) {
        if (setError) {
          setError(<Heading size="4">Kunde inte hämta data. Vänligen försök igen</Heading>);
        }
        return false;
      }
      if (!response.data.companies[0] || !response.data.companies[0].companyName) {
        setError && setError(__('fetch_company_error'));
        return false;
      }

      // Format and send data
      const company = response.data.companies[0];
      company.companyOrgNo = orgNr; // we don't get back a formatted org number from roaring, so we use the one the user filled in from the front-end
      const signatories = response.data.signatories;

      const beneficials = response.data.beneficials;

      // Add some extra empty beneficials
      const bLength = beneficials.length;
      if (bLength < minBeneficials) {
        for (var x = 0; x < minBeneficials - bLength; x++) {
          beneficials.push({
            firstName: '',
            lastName: '',
            ssn: '',
            hide: true,
          });
        }
      }

      formatedRoaringData = { company, signatories, beneficials, beneficialsLength: bLength };
      setData && setData(formatedRoaringData);
    })
    .catch(err => {
      setLoading && setLoading(false);
      if (err.response && err.response.status === 400 && setError) {
        setError(
          <Heading size="4">En eller flera bolagspersoner eller firmatecknare saknar svenskt personnummer. Vänligen kontakta Hubins kundtjänst för hjälp med att registrera företaget.</Heading>
        );
        return;
      }
      const error = (err.response && err.response.data) ? `${__ui('roaring_error_message')}` : __ui("standard_error_message", { email: __ui('platform_email'), tel: __ui('platform_tel') });
      setError && setError(error);
      formatedRoaringData = error;
    });

  return formatedRoaringData;
};

function GetCompanyRoaringData({ setRoaringData }) {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [companyOrg, setCompanyOrg] = useState({ value: '', error: false });

  const fetchCompanyData = useCallback(async (e) => {
    try {
      e.preventDefault();

      if (companyOrg.error) {
        return;
      }

      getRoaringData(companyOrg.value, setRoaringData, setLoading, setFormError);

    } catch {
      setLoading(false);
      setFormError(__ui("standard_error_message", { email: __ui('platform_email'), tel: __ui('platform_tel') }));
      return false;
    }

  }, [companyOrg.value]
  );

  return (
    <form onSubmit={fetchCompanyData} className="wrapper wrapper--narrow box">
      <section className="section section--no-border">
        <Heading size="3" center className="s-top-md">{__ui('corporate_account')}</Heading>
        <p>{__ui('corporate_account_info')}</p>
      </section>
      <section className="section section--no-border">
        <Input
          // className="maxwidth"
          placeholder="XXXXXX-XXXX"
          required
          type="text"
          label={__('corporate_identity_number')}
          error={companyOrg.error}
          value={companyOrg.value}
          callback={(value) => {
            setCompanyOrg({
              value,
              error: orgNumber(value),
            });
          }}
        />
        <p className="clarification s-md">{__('pre_company_roaring_info')}</p>

        <footer className="button__wrap button__wrap--center">
          <Button
            className="spacing"
            type="submit"
            loading={loading}
          >
            {__('fetch_company_data')}
          </Button>
        </footer>

        <ErrorMessage text={formError} showError={formError} />

      </section>
    </form>
  );
}

GetCompanyRoaringData.propTypes = {
  setRoaringData: PropTypes.func,
};

export default GetCompanyRoaringData;
