import React, { useState, useEffect, useContext } from 'react';
import __, {__route} from 'localisation';
import Modal from '@hubins/components/Modal';
import CreateForm from 'modules/CreateForm'
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import { loadForm, createAnswer } from 'helpers/form';
import { FormErrorContext } from '@hubins/components/FormErrorHandler';
import Heading from '@hubins/components/Heading';
import Button, { ButtonWrap } from '@hubins/components/Button';
import { useReportContext } from '@hubins/components/ReportContext';

const TermsModal = ({ open, setOpen, portfolio, setAlerts }) => {
  const { refetch } = useReportContext();
  const { setError } = useContext(FormErrorContext);
  const [form, setForm] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (open && portfolio) {
      const portfolioUuid = portfolio.uuid;
      const isCompany = portfolio.active_profile.type === 'COMPANY'
      const key = isCompany ? 'c_terms' : 'p_terms';
      loadForm(key, 'register', portfolioUuid, null, setForm);
    } else {
      setForm(null);
    }
  }, [open, portfolio])

  const submit = async (values) => {
    try {
      setLoading(true);
      const portfolioUuid = portfolio.uuid;
      const body = {
        key: form.key,
        type: form.type,
        version: form.version,
        language: form.language,
        portfolioUuid,
        data: values
      }
      await createAnswer(body, setError);
      refetch();
      setSubmitted(true);
      setLoading(false);
      setAlerts({});
    } catch (e) {
      setLoading(false)
      console.error(e.message);
    }
  }

  return (
    <Modal open={open}>
      {form ? (
      <>
          {submitted ? (
            <>
              <Heading size="3">Dina svar är uppdaterade</Heading>
              <p>Dina svar är nu uppdaterade och ni kan finna ert uppdaterade resultat från Krav- och Behovsprövningen via länken nedan.</p>
              <ButtonWrap>
                <Button className='cta' onClick={() => setOpen(false)}>
                  Bekräfta mottagandet
                </Button>
                <Button className='white' href={`${__route('da_so_updated')}/${portfolio?.active_profile?.uuid}/${portfolio.uuid}`}>
                  Gå till den uppdaterade resultatsidan
                </Button>
              </ButtonWrap>
            </>
          ) : (
            <CreateForm
              formData={form.content}
              submitType={submit}
              buttonProps={{ icon: "arrow right 1" }}
              submitText={"Bekräfta och gå vidare"}
              loading={loading}
              disabled={loading}
            />
          )}
      </>
      ) : (
          <LoadingBox />
      )}
    </Modal>
  )
};

export default TermsModal;