import React, { useState, useEffect, useCallback } from 'react';
import Modal from '@hubins/components/Modal';
import { motion, AnimatePresence } from 'framer-motion';
import { orgNumber } from 'components/Validation';
import getRoaringData from './getRoaringData';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Input from '@hubins/components/Input';
import __, { __route } from 'localisation/hubins';

type Props = {
  open: boolean;
  closeModal: () => void;
};

const CompanyRoaringModal = ({open, closeModal} : Props) => {

  const [roaringData, setRoaringData] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [roaringError, setRoaringError] = useState(false);
  const [companyOrgError, setCompanyOrgError] = useState(false);
  const [companyOrg, setCompanyOrg] = useState('');

  const submit = (e) => {
    e.preventDefault();
    const value = e.target.org_number.value;
    const orgNumberError = orgNumber(value, 'any', false);
    if (!orgNumberError) {
      getRoaringData(companyOrg, setRoaringData, setLoading, setRoaringError);
    }
  };

  const handleChange = useCallback((value) => {
    setCompanyOrg(value);
    setCompanyOrgError(orgNumber(value, 'any', false));
  }, [setCompanyOrg]);

  return (
    <Modal callback={closeModal} open={open}>
      {roaringError ? (
        <>
          {roaringError}<br />
          <ButtonWrap>
            <Button onClick={() => { setRoaringError(false); }}>Försök igen</Button>
          </ButtonWrap>
        </>
      ) : (
        <form
          key="form"
          onSubmit={submit}
        >
          <div>
            <Input
              disabled={!!roaringData}
              name="org_number"
              placeholder="XXXXXX-XXXX"
              required
              type="text"
              label={__('corporate_identity_number')}
              value={companyOrg}
              error={companyOrgError ? __('org_validation_error') : false}
              callback={handleChange}
            />
          </div>
          {!roaringData && (
            <Button
              loading={loading}
              type="submit"
              className="cta spacing s-top-xl"
              iconColor='white'
              size="large"
              icon="Download Dash Arrow 1"
            >
              Hämta uppgifter
            </Button>
          )}
          {roaringData && (
            <>
                <AnimatePresence>
                  <motion.div className="p s-top-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <p>Vänligen kontrollera att bolagsnamnet nedan är korrekt innan du går vidare. </p>
                  </motion.div>
                </AnimatePresence>

                <AnimatePresence>
                  <motion.div className="p"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <Input
                      locked
                      label={__('company_name')}
                      value={roaringData.company.companyName}
                    />
                  </motion.div>
                </AnimatePresence>

                <motion.div layoutId="buttons" className="button__wrap s-top-md centeronmobile">
                  <Button
                    href={`${__route('c_presale')}/new/${roaringData.company.companyOrgNo}`}
                    className="spacing cta"
                    icon="arrow right 1"
                    iconColor='white'
                    size='large'
                  >
                    {__('continue')}
                  </Button>
                  <Button
                    className="spacing link"
                    size='large'
                    onClick={() => {
                      setRoaringData(false);
                    }}
                  >
                    {__('restart')}
                  </Button>
                </motion.div>
            </>
          )}
        </form>
      )}

    </Modal>
  )

};

export default CompanyRoaringModal;