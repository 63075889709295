import React from 'react';
import Wrapper from '@hubins/components/Wrapper';
import { useReportContext } from '@hubins/components/ReportContext';
import LoadingPage from '@hubins/components/LoadingPage';
import AccountSelector from '@hubins/components/AccountSelector';
import Heading from '@hubins/components/Heading';
import { clientUseSourceOfFunds } from 'queries/sourceOfFunds';
import { Columns, Column } from 'components/Columns';
import __ from 'localisation';
import SourceOfFundsSummary from '@hubins/components/SourceOfFundsSummary';
import { useRiskAssessment } from 'queries/risks';
import SourceCards from 'components/SourceCards';

const ManageSourceOfFunds = () => {

    const {
      ready,
      portfolios,
      setPortfolioUuid,
      portfolioUuid,
    } = useReportContext();

    const { data, isFetched, isLoading } = clientUseSourceOfFunds(portfolioUuid);
    const { data: riskAssessment, isFetched: isRiskAssessmentFetched, isLoading: isRiskAssessmentLoading } = useRiskAssessment(portfolioUuid);

    if (!ready || isLoading || !isFetched || !isRiskAssessmentFetched || isRiskAssessmentLoading) return <LoadingPage />;

    return (
      <Wrapper>
        <Columns>
          <Column m="6" l="7" xl="9">
            <Heading size={2} className="s-bottom-xl">{__('manage_sof')}</Heading>
            <AccountSelector
              portfolios={portfolios}
              setPortfolioUuid={setPortfolioUuid}
              portfolioUuid={portfolioUuid}
            />
            <p>{__('manage_sof_description')}</p>
          </Column>
          <Column m="6" l="5" xl="3" className="s-bottom-xl">
            <SourceOfFundsSummary sources={data} />
          </Column>
          <Column xs="12">
            <SourceCards
              sources={data}
              isHighRisk={riskAssessment && typeof riskAssessment === 'number' && riskAssessment > 2}
              portfolioUuid={portfolioUuid}
              premium={true}
            />
          </Column>
        </Columns>
    </Wrapper>
  )
};

export default ManageSourceOfFunds;