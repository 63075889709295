import React, { useState, useEffect } from 'react';
import __, { __route } from 'localisation/hubins';
import CreateForm from "modules/CreateForm";
import { Columns, Column } from 'components/Columns';
import { loadForm, createAnswer } from 'helpers/form';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import { authOperations } from '../../state/ducks/auth';
import middleman from 'helpers/middleman';
import { connect } from 'react-redux';
import { alertsOperations } from '../../state/ducks/alerts';
import { formDataToCollection } from 'helpers/filters';
import { Redirect } from 'react-router-dom';

const Roaring = ({
  updateUser,
  updateAlerts,
  removeAlert,
  registered,
  formResponse,
}) => {
  const [form, setForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);

  const submitTerms =  async () => {
    try {
      if (formResponse) {
        await createAnswer(formResponse);
      }
      setRedirect(true);
    } catch (e) {
      console.error(e.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (registered) {
      submitTerms();
    }
  }, [registered]);

  useEffect(() => {
    loadForm('p_roaring', 'register', null, null, setForm);
  }, []);

  useEffect(() => {
    if (error) {
      setLoading(false);
      updateAlerts({
        id: 'signupFormsSummary',
        type: 'danger',
        closeButton: true,
        content: <>{__("standard_error_message", { email: __('platform_email'), tel: __('platform_tel') })}: {error}</>,
      });
    } else {
      removeAlert('signupFormsSummary');
    }
  }, [error]);

  const submit = async (values) => {
    try {
      if (registered) {
        submitTerms();
        return;
      }
      setLoading(true);
      const response = await middleman.promisePost(
        `/v2/fa/register/quantum/`,
        formDataToCollection(values)
      );
      updateUser(response.data);
    } catch (e) {
      setError(e.message);
    }
  };

  if (redirect) {
    return <Redirect to={__route('root')} />;
  }


  return (
    <Columns>
      <Column m="6">
        {form ? (
          <CreateForm
            formData={form.content}
            submitType={submit}
            submitText={__('save_and_continue')}
            buttonProps={{ icon: 'arrow right 1', iconColor: "white", size: "large" }}
            loading={loading}
            disabled={loading}
          />
        ) : (
          <LoadingBox />
        )}

      </Column>
      <Column m="6">
      </Column>
    </Columns>
  );

};

const mapDispatchToProps = {
  updateUser: authOperations.updateUser,
  updateAlerts: alertsOperations.updateAlerts,
  removeAlert: alertsOperations.removeAlert,
};

function mapStateToProps(state) {
  return {
    registeredUser: state.auth.User.registeredUser,
    registered: state.auth.User.registered,
    authToken: state.auth.User.authToken,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Roaring);
