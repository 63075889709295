import React from 'react';
import Heading from '@hubins/components/Heading';
import Button from "@hubins/components/Button";
import { Columns, Column } from "components/Columns";
import Icon from "@hubins/components/IconNew";
import __ from "localisation";
import './index.scss';
import Badge from '@hubins/components/Badge';

const SecurityCard = ({ name, type, original, closable, badge, fullsize, callback }) => {

  const renderClose = () => {
    return <Column xs={1} s={2} m={2} className="equal-height">
      <Button
        className="justify-left"
        onClick={() => {
          // callback();
          closable();
        }}
        onlyIcon
      >
        <Icon
          anim=""
          ariaHidden={undefined}
          className="round"
          label="check"
          color="primary"
          fallback="fallback"
          icon="subtract"
          size={16}
          spacing=""
        />
      </Button>
    </Column>;
  };

  const renderBadge = (prop) => {
    let display = null;
    let color = null;
    switch (prop) {
      case "init":
        display = "3/6 Information";
        color = "danger";
        break;
      case "pending":
        display = "Granskning pågår";
        color = "warning";
        break;
      default:
        break;
    }
    return <Column xs={3} s={3} m={3} l={3} xl={2} className="equal-height">
      <Badge
        className="justify-left"
        color={color}
      >{display}
      </Badge>
    </Column>;
  };

  const renderCTA = () => {
    const className = badge ? "spacing withicon" : "spacing cta withicon withicon--left";

    return <Button
      className={className}
      onClick={() => {
        callback();
      }}
    >
      {badge ? "Skicka in information" : __("tof_add_investment")}
      <Icon
        anim=""
        ariaHidden={undefined}
        className=""
        color=""
        fallback="fallback"
        icon={badge ? "arrow right 1" : "add"}
        size="small"
        spacing=""
      />
    </Button>;
  };

  return (
    <Column
      xs={fullsize ? 12 : 12}
      s={fullsize ? 12 : 6}
      m={fullsize ? 12 : 6}
      border
      bg={original && "neutral-2"}
      className={original && "dashed"}
    >
      <div
        style={{
          height: '100%',
          padding: '1rem'
        }}
      >
        <Columns justify-space-between>
          <Column xs={4} s={8} m={8} className="equal-height">
            <Heading size="5" className="smallheading">{name}</Heading>
          </Column>
          {closable && renderClose()}
          {badge && renderBadge(badge)}
          <Column xs={12} s={12} m={12} className="equal-height no-top-margin">
            <p className="clarification">{type === null ? "Ny investering" : __(type)}</p>
          </Column>
          {badge !== "pending" &&
            <Column xs={12} s={12} m={12} className="equal-height self-end">
              {!closable && renderCTA()}
            </Column>
          }
        </Columns>
      </div>
    </Column >
  );
};

export default SecurityCard;
