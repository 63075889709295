import React, { useEffect, useRef, useState } from 'react';
import Heading from '@hubins/components/Heading';
import Wrapper from "@hubins/components/Wrapper";
import __ from "localisation";
import CaseTable from 'components/CaseTable';
import 'components/CaseTable/index.scss';
import './index.scss';
import InsuranceVsDirectBars from 'components/Charts/InsuranceVsDirect/InsuranceVsDirectBars';
import { InsuranceVsDirectBarsLabels } from 'components/Charts/InsuranceVsDirect/InsuranceVsDirectBarsLabels';
import { calcRiskPremium } from "@hubins/calculations";

import { getMoney, getAge } from 'helpers';

import {
  generateData as generateInsuranceVsDirectData,
} from "./calcualtions";
import Earnings from './Earnings';
import ResultBox from './ResultBox';
import ResultTable from './ResultTable';

import { connect } from 'react-redux';

const TransferResult = (props) => {

  const [open, setOpen] = useState(false);
  const isCompany = useRef(props.contact_juridical === "CO");

  const handleToogle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const formResponse = props.holding.formResponse.response;

  const AMOUNT = formResponse.current_value;
  const ACQUISITION = formResponse.acquisition_value;
  const R = formResponse.expected_return_per_year;
  const YEARS = formResponse.investment_horizon;
  const MONTHS = formResponse.investment_horizon * 12;
  const AGE = getAge(props.national_identity_number);

  const STATIC_VALUES = {
    INSURANCE_VARIABLE_FEE:
      __(props.holding.security.type) === "Noterade aktier"
        ? 0.005
        : 0.01,
    INSURANCE_FIXED_FEE:
      (
        __(props.holding.security.type) === "Noterade aktier" || __(props.holding.security.type) === "Onoterade aktier"
      )
        ? 0
        : 1800,
    TRANSACTION_FEE:
      (
        __(props.holding.security.type) === "Noterade aktier" || __(props.holding.security.type) === "Onoterade aktier"
      )
        ? 0
        : 300,
    DIRECT_INVESTMENT_TAX:
    isCompany.current
      ? __(props.holding.security.type) === "Onoterade aktier"
        ? 0
        : 0.206
      :  __(props.holding.security.type) === "Onoterade aktier"
        ? 0.25
        : 0.3,
    GOVERNMENT_BORROWING_RATE: 0.0023,
    RISK_PREMIUM: calcRiskPremium(AGE),
  };

  const dataChart = generateInsuranceVsDirectData(
    AMOUNT,
    MONTHS > 24 ? MONTHS + 12 : MONTHS, // usually is months + 1, but temp disable that due to the fixed cost calulation gets wrong. / Jonas  ---  old comment: show also the 12 / 24 month, or when only one point per year, add a last year, to show correct end state
    R,
    STATIC_VALUES,
    false
  );

  const hubins = dataChart[YEARS].hubins;
  const hubinsTax = dataChart[YEARS].hubinsTax;
  const hubinsFees = dataChart[YEARS].hubinsFees;

  const direct = dataChart[YEARS].direct;
  const directFees = dataChart[YEARS].directFees;

  const increse = hubins - direct;

  const gains = () => {
    if(increse > 0) {
      return "tjäna";
    }
    if(increse <= 0) {
      return "förlora";
    }
  };

  useEffect(() => {
    props.callback(increse);
  }, []);

  const data = [
    {
      name: 'Direktinvestering',
      netRetDir: direct,
      taxDirInv: directFees,
    },
    {
      name: 'Kapitalförsäkring',
      taxIns: hubinsTax,
      netRetIns: hubins,
      feeIns: hubinsFees
    }
  ];

  const realized = AMOUNT - ACQUISITION;

  const renderOpen = () => {
    return <>
      <CaseTable
        rows={[
          {
            text: getMoney(AMOUNT, "kr", 0),
            title: 'Aktuellt värde'
          },
          {
            text: getMoney(props.holding.acquisition_value, "kr", 0),
            title: 'Anskaffningsvärde'
          },
          {
            text: props.holding.expected_return_per_year + " %",
            title: 'Målavkastning'
          },
          {
            text: props.holding.investment_horizon + " år",
            title: 'Investeringsperiod'
          }
        ]}
        noHeading />
      <Earnings hubins={hubins} direct={direct} initial={AMOUNT} increse={increse}></Earnings>
      <InsuranceVsDirectBars
        data={data}
      />
      <InsuranceVsDirectBarsLabels
        data={data}
      />
      <ResultTable data={data}></ResultTable>
      <ResultBox realized={realized}></ResultBox>
      <div className='section-padding-small'>
        <a className='clickable c-accent' onClick={handleToogle}>Stäng</a>
      </div>
    </>;
  };

  return (
    <>
      <Wrapper tight noBg>
        <Heading size="4" className="smallheading">{props.holding.security.name}</Heading>
        <p>Om du skulle välja att flytta in dina <strong>{__(props.holding.security.type).toLowerCase()}</strong> i <strong>{props.holding.security.name}</strong>,
        skulle du {gains()} <strong>{getMoney(increse, "kronor", 0)}</strong> under de <strong>{YEARS} år</strong> du avser hålla din investering.
         Detta skulle resultera i en total avkastning om <strong>{getMoney(hubins, "kronor", 0)}</strong>.
        </p>
        <div className='section-padding-small'>
          {open
            ? renderOpen()
            : <a className='clickable c-accent' onClick={handleToogle}>Klicka här för att se fullständigt resultat</a>
          }
        </div>
      </Wrapper>
    </>
  );
};

function mapStateToProps(state) {
  return {
    contact_juridical: state.auth.User.active_profile.fa_connection.contact_juridical,
    national_identity_number: state.auth.User.national_identity_number,
  };
}

export default connect(
  mapStateToProps,
)(TransferResult);
