import React from 'react';
import Heading from '@hubins/components/Heading';
import Wrapper from '@hubins/components/Wrapper';
import AptitudeTestResult from '@hubins/components/AptitudeTestResult';
import __ from 'localisation';

const investmentForms = [
  {
    formKey: 'cryptocurrency',
    title: __('knowledge_test_cryptocurrency'),
  },
  {
    formKey: 'stock',
    title: __('knowledge_test_stock'),
  }
];

const AptitudeTests = () => {
  return (
    <Wrapper>
      <Heading size="2">{__('aptitude_tests')}</Heading>
      {investmentForms.map((form, index) => (
        <AptitudeTestResult key={index} formKey={form.formKey} title={form.title} />
      ))}
    </Wrapper>
  );
};

export default AptitudeTests;
