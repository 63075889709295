import React from 'react';
import Heading from '@hubins/components/Heading';
import { Link } from "react-router-dom";
import Icon from "@hubins/components/IconNew";
import Box from 'components/Box';
import './index.scss';

const InfoCard = ({ title, color, icon, href, meta = '', className }) => {

  return (
    <Box
      bg={color}
      className={`info-card ${className ? className : ''}`}
    >
      <div className="info-card__header">
        <div className='info-card__number'>{meta}</div>
        <Icon icon={icon} size={16} />
      </div>
      <Heading size="4">
        {title}
      </Heading>
      <div className='info-card__footer'>
        <Link to={href}>
          <Icon icon="arrow right 1" size={16} />
        </Link>
      </div>
    </Box>
  );
};

export default InfoCard;
