import React, { useState, useEffect } from 'react';
import __, { __route } from 'localisation/hubins';
import Wrapper from '@hubins/components/Wrapper';
import { Columns, Column } from 'components/Columns';
import { loadForm } from 'helpers/form';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import CreateForm from "modules/CreateForm";
import { connect } from "react-redux";
import { authOperations } from "../../state/ducks/auth";
import middleman from 'helpers/middleman';
import { createAnswer } from 'helpers/form';
import { Redirect } from "react-router-dom";

const Presale = ({ match, updateUser }) => {
  const { profileUuid } = match.params;
  const [form, setForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    loadForm(
      'c_presale', 'register', null, null, setForm
    );
  }, []);

  const submit = async (values) => {
    try {
      setError(false);
      setLoading(true);
      const res = await middleman.promisePost(`/v1/fa/auth/portfolio/create`, { profileUuid });
      const createdPortfolio = res.data;

      if (!createdPortfolio || !createdPortfolio.uuid) {
        throw new Error('Something went wrong');
      }

      const body = {
        key: form.key,
        type: form.type,
        version: form.version,
        language: form.language,
        data: values,
        profileUuid,
        portfolioUuid: createdPortfolio.uuid
      };
      await createAnswer(body);

      const updatedUser = await middleman.get('/user');
      updateUser(updatedUser.data);

      setRedirect(`${__route('register_summary')}/${profileUuid}/${createdPortfolio.uuid}`);

    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <Wrapper>
      <Columns>
        <Column m="6">
          {form ? (
            <CreateForm
              formData={form.content}
              submitType={submit}
              submitText={__('accept_and_proceed')}
              // buttonProps={{ icon: 'save' }}
              loading={loading}
              disabled={loading}
              error={error}
            />
          ) : (
            <LoadingBox />
          )}
        </Column>
        <Column m="6">

        </Column>
      </Columns>
    </Wrapper>
  );
};

export default connect(null, {
  updateUser: authOperations.updateUser,
})(Presale);
