import React, { useState, useEffect } from 'react';
import __, { __route } from 'localisation';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import { Columns, Column } from 'components/Columns';
import Main from 'components/Main';

import CreateForm from 'modules/CreateForm';
import { loadForm } from 'helpers/form';
import { appendAllFieldValues } from 'helpers/formscms';
import { formDataToCollection } from 'helpers/filters';
import TransactionResult from './TransactionResult';
import middleman from 'helpers/middleman';
import { Redirect } from 'react-router-dom';

const Create = ({
  transaction,
  setSelected,
  setTransaction,
  portfolioUuid,
  transactionsLoading,
  portfolio,
  innerRef,
  setButtonLoading,
  sources,
  totalAvailableAmount,
  isHighRisk,
  fetchRisk,
  setDisabled,
}) => {
  const [error, setError] = useState(false);
  const [form, setForm] = useState(false);
  const [formContent, setFormContent] = useState(false);
  const [result, setResult] = useState(false);
  const [autoComplete, setAutoComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (form) {
      if (transaction) {
        const formResponse = (transaction.formResponses && transaction.formResponses.length > 0)
          ? transaction.formResponses[0]
          : false;
        if (formResponse) {
          const formWithResponse = appendAllFieldValues(form.content, formResponse.response);
          setFormContent(formWithResponse);
          return;
        }
      }
      if (!transactionsLoading)
        setFormContent(form.content);
    }
  }, [transaction, form, transactionsLoading]);

  useEffect(() => {
    loadForm('deposit', 'transaction', false, portfolioUuid, setForm);
    setDisabled(false);
  }, []);

  const submit = async (values) => {
    setButtonLoading(true);

    try {
      const formData = formDataToCollection(values);
      const request = transaction
        ? middleman.promisePost(`/transactions/update/${portfolioUuid}`, {
          id: transaction.uuid,
          data: formData,
          ...form,
        })
        : middleman.promisePost(`/transactions/create/${portfolioUuid}`, {
          data: formData,
          ...form,
        });
      const response = await request.then(res => res.data);
      await fetchRisk();
      const autoCompleteQuestion = formData?.select_sources;
      if (autoCompleteQuestion && Array.isArray(autoCompleteQuestion)) {
        setAutoComplete(autoCompleteQuestion.includes('select_sources_automatically'));
      }
      setTransaction(response);
      setResult(response);
    } catch (e) {
      setError(__('Något gick fel. Vänligen försök igen.'));
    }
    setButtonLoading(false);
  };

  const proceed = async () => {
    const enoughAvailableAmount = (transaction.amount / 100) <= totalAvailableAmount;
    const sourceAmountKey = isHighRisk ? 'highRiskAvailableAmount' : 'availableAmount';
    const someSourceHasNegativeAmount =
      sources.some((source) => {
        return source[sourceAmountKey] < 0;
      }
      );
    const pagesToGoForward = (enoughAvailableAmount && !someSourceHasNegativeAmount) ? 2 : 1;

    if (autoComplete && enoughAvailableAmount && !someSourceHasNegativeAmount) {
      try {
        setLoading(true);
        const amount = transaction.amount / 100;

        // sort source by highest available amount
        const sortedSources = sources.sort((a, b) => b[sourceAmountKey] - a[sourceAmountKey]);

        const sourceTransactions = sortedSources.reduce((arr, source) => {
        // calculate the total amount taken so far
          const totalAmountSoFar = arr.reduce((sum, item) => sum + item.amount, 0);
          // calculate the remaining amount
          const remainingAmount = amount - totalAmountSoFar;

          if (remainingAmount <= 0) {
            return arr;
          }

          const availableAmount = source[sourceAmountKey];

          arr.push({
            id: source.uuid,
            amount: availableAmount < remainingAmount ? availableAmount : remainingAmount,
          });
          return arr;
        }, []);

        const usedAmount = sourceTransactions.reduce((sum, item) => sum + item.amount, 0);

        if (usedAmount !== amount) {
          console.error('The amount used does not match the transaction amount');
          setResult(false);
          setSelected((prevSelected) => prevSelected + pagesToGoForward);
        }

        console.log('sourceTransactions', sourceTransactions);

        const response = await middleman.promisePost(`/transactions/update/${portfolioUuid}`, {
          id: transaction.uuid,
          sources: sourceTransactions
        }).then(res => res.data);

        if (response.status === 'pending') {
          setRedirect(`${__route("depositInstructions")}/s/${response.uuid}`);
        } else {
          setError('Något gick fel. Vänligen försök igen.');
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError('Något gick fel. Vänligen försök igen.');
      }
    } else {
      setResult(false);
      setSelected((prevSelected) => prevSelected + pagesToGoForward);
    }


  };

  const close = () => {
    setResult(false);
    setButtonLoading(false);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (transactionsLoading || !formContent) {
    return (<LoadingBox />);
  }

  return (
    <>
      <TransactionResult
        transaction={result}
        open={!!result}
        portfolio={portfolio}
        proceed={proceed}
        close={close}
        error={error}
        loading={loading}
      />
      <Main>
        <Columns>
          <Column m="6" s="12">
            <CreateForm
              formData={formContent}
              submitType={submit}
              error={error}
              buttonStyle="hidden"
              submitRef={innerRef}
              submitText="Gå vidare"
              disabled={result}
            />
          </Column>
          <Column m="6" s="12">
            <img src="/illustrations/premieinsattning.svg" />
          </Column>
        </Columns>
      </Main>
    </>
  );
};

export default Create;
