import React, { useEffect, useState } from 'react';
import __, { __route } from 'localisation';
import __reg from 'localisation/hubins/registration';
import Wrapper from '@hubins/components/Wrapper';
import { Columns, Column } from 'components/Columns';
import Heading from '@hubins/components/Heading';
import middleman from 'helpers/middleman';
import LoadingPage from '@hubins/components/LoadingPage';
import ListButton from '@hubins/components/ListButton';
import Button from '@hubins/components/Button';
import FormInfo from '@hubins/components/FormInfo';
import newInsuranceImgUrl from 'assets/illustrations/new_insurance.svg';
import Icon from '@hubins/components/IconNew';
import CompanyPersonnelPowersOfAttorney from '@hubins/components/CompanyPersonnelPowersOfAttorney';


const keyToIcon = {
  p_presale: 'Task List Text 1',
  c_presale: 'Task List Text 1', // Not sure what icon
  p_kt: 'Wild Bird Owl Body',
  c_kt: 'Wild Bird Owl Body', // Not sure what icon
  p_da_terms: 'Notes Checklist Flip',
  p_da_so: 'Task List Check 1',
  c_da_terms: 'Notes Checklist Flip', // Not sure what icon
  c_da_so: 'Task List Check 1', // Not sure what icon
  p_kyc: 'ql',
  p_ins: 'ql',
  p_terms: 'ql',
  p_aftersale: 'ql',
  c_bi: 'ql', // Not sure what icon
  c_kyc: 'ql', // Not sure what icon
  c_ins: 'ql', // Not sure what icon
  c_hm: 'ql', // Not sure what icon
  c_terms: 'ql', // Not sure what icon
  c_aftersale: 'ql', // Not sure what icon
  c_sof: 'ql', // Not sure what icon
  p_sof: 'ql', // Not sure what icon - Källa till kapital
  c_sofdoc: 'ql', // Not sure what icon
  p_sofdoc: 'ql', // Not sure what icon
  sow: 'ql', // Not sure what icon
};

const SignupSummary = ({ match }) => {

  const privateRegistration = [
    {
      keys: ['p_presale'],
      route: __route('p_presale'),
    },
    {
      keys: ['p_kt'],
      route: __route('p_kt'),
    },
    {
      keys: ['p_da_terms', 'p_da_purpose', 'p_da_transactions', 'p_da_products', 'p_da_target_return', 'p_da_tax', 'p_da_beneficiaries'],
      route: __route('p_da'),
    },
    {
      keys: ['p_da_so'],
      route: __route('p_da_so'),
    },
    {
      keys: ['p_kyc'],
      route: __route('p_kyc'),
    },
    {
      keys: ['p_ins'],
      route: __route('p_ins'),
    },
    {
      keys: ['sow'],
      route: __route('reg_sow'),
    },
    {
      keys: ['p_terms'],
      route: __route('p_terms'),
    },
    {
      keys: ['p_aftersale'],
      route: __route('p_aftersale'),
    },
  ];

  const companyRegistration = [
    {
      keys: ['c_presale'],
      route: __route('c_presale'),
    },
    {
      keys: ['c_kt'],
      route: __route('c_kt')
    },
    {
      keys: ['c_da_terms', 'c_da_purpose', 'c_da_transactions', 'c_da_products', 'c_da_target_return', 'c_da_tax', 'c_da_beneficiaries'],
      route: __route('c_da')
    },
    {
      keys: ['c_da_so'],
      route: __route('c_da_so')
    },
    {
      keys: ['c_bi'],
      route: __route('c_bi')
    },
    {
      keys: ['c_kyc'],
      route: __route('c_kyc')
    },
    {
      keys: ['c_ins'],
      route: __route('c_ins')
    },
    {
      keys: ['p_kyc'],
      title: __reg('c_hm'),
      route: __route('c_hm')
    },
    {
      keys: ['sow'],
      route: __route('company_reg_sow'),
    },
    {
      keys: ['c_terms'],
      route: __route('c_terms')
    },
    {
      keys: ['c_aftersale'],
      route: __route('c_aftersale')
    },
  ];

  const { profileUuid, portfolioUuid } = match.params;
  const [tags, setTags] = useState([]);
  const [profile, setProfile] = useState(false);
  const [portfolio, setPortfolio] = useState(false);

  const fetchData = async () => {

    const response = await middleman.get(`/registration/progress/${profileUuid}/${portfolioUuid}`);
    setTags(response.data.tags);
    setProfile(response.data.profile);
    setPortfolio(response.data.portfolio);
  };

  const isCompany = profile.type === 'COMPANY';

  const formsList = isCompany ? companyRegistration : privateRegistration;

  useEffect(() => {
    fetchData();
  }, []);

  if ((!profile || !portfolio)) {
    return <LoadingPage />;
  }


  let foundFirstItem = false;

  const formattedItems = formsList.reduce((list, item, index) => {
    const done = item.keys.every((key) => (tags.indexOf(key) > -1));

    const available = index === 0
      ? (done ? false : true)
      : (list[index - 1].disabled && list[index - 1].done);

    const disabled = foundFirstItem || !available || done;

    if (!disabled) {
      foundFirstItem = true;
    }

    list.push({
      key: item.keys[0],
      title: item.title || __reg(item.keys[0]),
      disabled,
      href: `${item.route}/${profileUuid}/${portfolioUuid}`,
      done: done,
    });
    return list;
  }, []);

  const everythingDone = formattedItems.every(item => item.done);

  return (
    <Wrapper>
      <Columns>
        <Column m="6">
          {everythingDone ? (
            <>
              {portfolio.status !== 'issued' ? (
                <>
                  <Heading>Bra jobbat!</Heading>
                  <p className='s-bottom-xl'>Din ansökan och dokumentation är nu inskickad.</p>
                  <FormInfo color='info' title="Vad händer nu?" text={
                    <p>
                      Vi går nu igenom din försäkringsansökan och behandlar de underlag du har skickat in. När detta är klart kommer vi att kontakta dig med inbetalningsuppgifter.
                      Har du några frågor kan du kontakta oss på <a href="mailto:kundservice@hubins.com" target="_blank" rel="noreferrer">kundservice@hubins.com</a> eller <a href="tel:0317971936" target="_blank" rel="noreferrer">031-797 19 36</a>.
                    </p>
                  } />
                  <Button className="s-top-xl" href={__route('root')}>Till mina konton</Button>
                </>
              ) : (
                <>
                  <Heading>Grattis</Heading>
                  <p>Kapitalförsäkringen är nu godkänd och du kan nu gå vidare och genomföra den första insättningen.
                    {isCompany && " Vänligen notera dock att samtliga verkliga huvudpersoner och firmatecknare måste logga in med personligt BankID på Hubins.com. Där behöver de svara på kundkännedomfrågor samt signera fullmakt åt bolagsrepresentaten för att försäkringen ska kunna utfärdas."}
                    {" "}Har du några frågor kan du kontakta oss på <a href="mailto:kundservice@hubins.com" target="_blank" rel="noreferrer">kundservice@hubins.com</a>  eller <a href="tel:0317971936" target="_blank" rel="noreferrer">031-797 19 36</a>.</p>
                  <Button href={__route('root')}>Till mina konton</Button>
                </>
              )}
            </>
          ) : (
            <>
              <Heading size="1" className="s-bottom-l">
                {__('ongoing_insurance')}
              </Heading>
              <Heading size="5" className='s-bottom-xxl'>
                {__('register_insurance_summary_text')}
              </Heading>
              <div className='flex flex--row align-center s-bottom-xl'>
                <div className='icon-border' style={{
                  marginRight: '24px'
                }}>
                  <Icon icon="user" size="24" color='primary' />
                </div>
                <div className='align-left'>
                  <p className='body-big'><strong>{profile.name}</strong></p>
                  <p className='clarification body-small s-top-bottom-none'>{profile.national_identity_number}</p>

                </div>
              </div>
              <div className="flex">
                {
                  formattedItems.map((item) => (
                    <ListButton
                      key={item.key}
                      href={item.href}
                      disabled={item.disabled}
                      actionIcon={item.done ? 'check signle' : item.disabled ? 'Lock 2' : 'arrow right 1'}
                      icon={keyToIcon[item.key]}
                      title={item.title}
                      actionText=''
                      selected={!item.disabled}
                    />
                  ))
                }
              </div>
            </>
          )}
        </Column>
        <Column m="6">
          {!(everythingDone && isCompany) && (
            <img src={newInsuranceImgUrl} alt="New insurance" />
          )}
          <CompanyPersonnelPowersOfAttorney
            signatories={profile.signatories}
            beneficialOwners={profile.beneficial_owners}
            profileId={profile.id}
          />
        </Column>
      </Columns>
    </Wrapper>
  );

};

export default SignupSummary;
