import React from 'react';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import DynamicLoadPage from '@hubins/components/DynamicLoadPage';

const ChartPieLoaded = DynamicLoadPage({
  loader: () => import('./Chart'),
  loadingComponent: LoadingBox,
});

const ChartPie = (props) => {
  return <ChartPieLoaded {...props} />;
};

export default ChartPie;
