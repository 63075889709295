import React, { useEffect, useState } from 'react';
import Heading from '@hubins/components/Heading';
import SecurityCard from "modules/SecurityCard";
import { Columns, Column } from "components/Columns";
import Wrapper from "@hubins/components/Wrapper";
import { useHistory } from "react-router-dom";
import Box from 'components/Box';
import Button, { ButtonWrap } from '@hubins/components/Button';
import { __route } from 'localisation';

import api from "api-connector";
import apienv from "../../server/apienv";
import InfoCard from 'modules/InfoCard';

const Transfer = () => {

  const baseUrl = apienv.base_url;
  const middleman = api(baseUrl);
  let history = useHistory();

  const [holdings, setHoldings] = useState();

  const getHoldings = async () => {
    const response = await middleman.get(`/holdings`);
    setHoldings(response.data);
  };

  const redirect = (uuid) => {
    history.push({
      pathname: __route('transfer_info'),
      state: { uuid }
    });
  };

  useEffect(() => {
    getHoldings();
  }, []);

  return (
    <>

      <Wrapper>
        <Columns>
          <Column s="12" m="6">
            <Heading size="2" className="f-mono-weight-light">
              Inflytt
            </Heading>
            <Heading size="4" className="c-neutral-6 f-mono-weight-light s-top-m">
              Curabitur blandit tempus porttitor. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
            </Heading>
            <Columns paddingSize="none" className="s-top-sm">
              <Column s="12" m="6">
                <InfoCard
                  title="Se din rapport"
                  color="secondary"
                  icon="bond"
                  href={__route('transfer_report')}
                  meta="02"
                />
              </Column>
              <Column s="12" m="6">
                <InfoCard
                  title="Teckna försäkring"
                  color="primary"
                  icon="products"
                  href="info"
                  meta="04"
                />
              </Column>

            </Columns>
            {holdings
              ? holdings.map((holding, index) => (
                <SecurityCard
                  key={index}
                  name={holding.security.name}
                  type={holding.security.type}
                  badge={holding.status}
                  fullsize
                  callback={() => redirect(holding.uuid)}
                ></SecurityCard>
              ))
              : null}
            <Box bg="primary">
              <Columns>
                <Column s="6">
                  <Heading size="3">
                    Starta en ny inflytt
                  </Heading>
                </Column>
                <Column s="6">
                  [Illustration here!]
                </Column>
                <Column s="12">
                  <p>Vill du veta mer om hur det går till att flytta in investeringar till Hubins? Klicka på videon nedan. Annars starta din inflytt redan nu.</p>
                </Column>
                <Column s="12">
                  <ButtonWrap>
                    <Button icon="plus" className="secondary" href={__route('transfer_start')}>
                      Starta en inflytt
                    </Button>
                    <Button icon="arrow right 1" className="cta">
                      Så funkar det
                    </Button>
                  </ButtonWrap>
                </Column>
              </Columns>
            </Box>
          </Column>
          <Column s="12" m ="6">
            {/* TODO: Add huge illustration here */}
            <Heading size="3" className="s-top-m">Inflytt steg för steg</Heading>
            <ul style={{ listStyle: 'none' }} className="no-padding body-big s-top-m">
              <li><span className='s-right-m'>01</span>Testa din investering</li>
              <li><span className='s-right-m'>02</span>Rapport</li>
              <li><span className='s-right-m'>03</span>Skicka in information </li>
              <li><span className='s-right-m'>04</span>Öppna kapitalförsäkring</li>
              <li><span className='s-right-m'>05</span>Signera överföringsfullmakt</li>
              <li><span className='s-right-m'>06</span>Invänta leverans</li>

            </ul>
          </Column>
        </Columns>
      </Wrapper>
    </>
  );
};

export default Transfer;
