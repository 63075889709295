import React from 'react';
import QRCode from "react-qr-code";
import __ from 'localisation';
import Icon from '@hubins/components/IconNew';
import HubinsAnim from './HubinsAnim';
import { illustrationVariants, contentVariants, submitVariants } from './animationData.js';
import { motion } from 'framer-motion';
import Heading from '@hubins/components/Heading';
import Button from '@hubins/components/Button';
import './index.scss';

const LoginModalQR = ({
  animateState,
  handleSubmit,
  handleAbort,
  redirectToSignup,
  reset,
  qrCode,
  openOnSameDevice,
  paused,
  resumePoll,
  hasScanned,
  autoStartToken,
}) => {

  return (

    <div className="loginform" key="login">
      <motion.div
        animate={animateState}
        // initial={{ opacity: 0 }}
        className="loginform__content">
        {(qrCode && !paused && !hasScanned) ? (
          <div className="login-modal-qr-code" style={{ height: "340px" }}>
            <QRCode
              bgColor="#02064C"
              fgColor="#FFCB94"
              value={qrCode}
            />
          </div>
        ) : (
          <HubinsAnim
            animateState={animateState} //waiting success visible?
            variants={illustrationVariants}
            platform="Hubins"
          />
        )}

        <motion.div
          key="logincontent"
          className="loginform__content"
        >
          <Heading element={motion.h2} size="3" animate className="s-bottom-s center">
            {__('here_login')}
          </Heading>
          <motion.form
            onSubmit={handleSubmit}
            className="loginform__form"
          >
            <motion.div className="center section-padding" variants={submitVariants}>
              <Button
                id="login-button"
                size="large"
                type="submit"
                className="cta spacing inverted"
                loading={animateState === 'waiting'}
                icon="bankid"
                iconSize={16}
                label="BankID"
              >
                {__('login_button')}
              </Button>
            </motion.div>
          </motion.form>
          {/* When the user started the login process */}
          <motion.div
            className={`loginform__content loginform__content--over ${animateState === 'waiting' ? '' : 'noclicks'}`}
            animate={animateState === 'waiting' ? 'visible' : 'hidden'}
            variants={contentVariants}
            onSubmit={handleSubmit}
            initial={{ opacity: 0 }}
            exit="exit"
          >
            <motion.div className="center">
              {/* <Heading element="h1" size="3" className="center c-white s-top-xl">{__('bankid_title_new')}</Heading> */}
              {hasScanned ? (
                <p>Skriv in din säkerhetskod i BankID-appen.</p>
              ) : (
                <p>
                    Starta BankID-appen på din telefon och tryck på ikonen för QR-kod i appen för att skanna QR-koden på den här sidan.
                </p>
              )}

              <motion.div className="s-top-md center">
                {paused ? (
                  <Button
                    className="button cta spacing inverted withicon withicon--medium large"
                    icon="bankid"
                    onClick={resumePoll}
                  >
                    Använd QR-kod
                  </Button>
                ) : (
                  <Button
                    className="button cta spacing inverted withicon withicon--medium large"
                    onClick={openOnSameDevice}
                    loading={!autoStartToken}
                    disabled={!autoStartToken}
                    icon="bankid"
                  >
                    {__('bankid_open')}
                  </Button>
                )}
                <motion.div className="center" >
                  <Button className="inverted link spacing" onClick={handleAbort}>
                    {__('bankid_abort')}
                  </Button>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
        {/* if error */}
        <motion.div
          className={`loginform__content loginform__content--over ${(animateState === 'error' || animateState === 'notFound') ? '' : 'noclicks'}`}
          animate={(animateState === 'error' || animateState === 'notFound') ? 'visible' : 'hidden'}
          variants={contentVariants}
          onSubmit={handleSubmit}
          initial={{ opacity: 0 }}
          exit="exit"
        >
          <Icon
            // color="c-warning"
            icon="Sign Warning"
            size={24}
            className="s-bottom-l "
          />
          <Heading element="h2" size={3}>
            {animateState === 'error' ? "Inloggning misslyckades" : "Hittade ingen användare"}
          </Heading>
          <p>
            {animateState === 'error'
              ? (
                <>Något gick fel under inloggningen. Vänligen försök igen. Om problemet kvarstår, vänligen kontakta oss via <a href={`mailto:${__('platform_email')}`}>{__('platform_email')}</a> eller på telefonnummer <a href="tel:0317971936">031-797 19 36</a>.</>)
              : "Hittade ingen användare. Vänligen kontrollera att du har skrivit in rätt personnummer och försök igen. Om du inte har ett konto kan du skapa ett här nedan."
            }
          </p>
          {animateState === 'notFound' && (
            <Button
              onClick={() => redirectToSignup()}
            >
              {__('create_account')}
            </Button>
          )}
          <Button
            className="cta spacing inverted"
            onClick={reset}
          >
            Försök igen
          </Button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default LoginModalQR;
