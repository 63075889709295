import React, { useRef } from 'react';
import { __ } from 'localisation';
import Wrapper from '@hubins/components/Wrapper';
import { getMoney } from 'helpers';
import Heading from '@hubins/components/Heading';
import LoadingPage from '@hubins/components/LoadingPage';
import { useHoldings, useReport } from 'queries/report';
import { useReportContext } from '@hubins/components/ReportContext';
import AccountSelector from '@hubins/components/AccountSelector';
import HoldingsTable from 'modules/Tables/Holdings';
import AccountsTable from 'modules/Tables/AccountsTable';
import { Columns, Column } from 'components/Columns';
import NoPortfolios from '../NoPortfolios';
import ConnectionsTable from '@hubins/components/ConnectionsTable';


const Holdings = () => {

  const {
    ready,
    portfolios,
    portfolio,
    setPortfolioUuid,
    portfolioUuid,
    noPortfolios,
  } = useReportContext();

  const endDate = useRef(new Date().toISOString().substr(0, 10));
  const startDate = '2010-01-01';

  const { data, isLoading } = useHoldings(startDate, endDate.current, portfolioUuid);
  const { data: report, isLoading: reportLoading, isFetched: reportIsFetched } = useReport(startDate, endDate.current, portfolioUuid);

  if (!ready) return <LoadingPage />;

  if (noPortfolios) {
    return (
      <NoPortfolios />
    );
  }

  return (
    <Wrapper>
      <Columns>
        <Column s="12">
          <Heading size="2" className="s-bottom-xl">
            Min portfölj
          </Heading>
        </Column>
        <Column l="6">
          <AccountSelector
            portfolios={portfolios}
            setPortfolioUuid={setPortfolioUuid}
            portfolioUuid={portfolioUuid}
          />
        </Column>
        <Column s="12">
          <Heading size="4" className="s-top-m">
            Totalt värde
          </Heading>
          <Heading size="2" className="s-bottom-m">
            {getMoney(portfolio?.market_value)}
          </Heading>
        </Column>
        <Column s="12">
          <Heading size="4" className="section-padding">
            {__('liquid_funds')}
          </Heading>
          <AccountsTable
            currency={reportIsFetched ? report.accounts.currency : ''}
            isLoading={reportLoading}
            accounts={reportIsFetched ? report.accounts : []}
          />
        </Column>
        {portfolio.royc_connection && (
          <Column s="12">
            <Heading size="4" className="section-padding">
              Kopplade plattformar
            </Heading>
            <ConnectionsTable />
          </Column>
        )}
        <Column s="12">
          <HoldingsTable
            holdings={data}
            loading={isLoading}
            currency={reportIsFetched ? report.accounts.currency : ''}
          />
        </Column>
      </Columns>
    </Wrapper>
  );

};

export default Holdings;
