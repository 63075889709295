import React, { useState, useEffect, useCallback } from "react";
import __ from "localisation";
import __reg from 'localisation/hubins/registration';
import Button from "@hubins/components/Button";
import Heading from '@hubins/components/Heading';
import { Columns, Column } from "components/Columns";
import Wrapper, { WrapperContent } from "@hubins/components/Wrapper";
import DownloadLink from "components/DownloadLink";
import Checkbox from "components/Checkbox";

import CompanyRoaring from 'modules/CompanyRoaring';
import { motion, AnimatePresence } from 'framer-motion';
import InputAutofill from 'components/InputAutofill';
import Input from "@hubins/components/Input";
import FormSelect from "components/FormSelect";

// BANK ID
import BankID from "modules/BankID";
import BankIDV6 from "modules/BankID/BankIDV6";
import { ssn as ssnValidation } from 'components/Validation';
import { useFeature } from '@growthbook/growthbook-react';
import { bypass } from "states/utilities";

import Main from "components/Main";

const Terms = ({ juridical, submitUser, setCompany }) => {

  const [data, setData] = useState(false);
  const [confirmed, setConfirmed] = useState(null);
  const [approves, setApproves] = useState({
    'approves': {
      value: false,
      error: null,
    }
  });

  const BANKID_V6 = useFeature('bankid-api-v6').on;

  const BankIDComponent = BANKID_V6 ? BankIDV6 : BankID;

  const handleRoaringResponse = (roaringData) => {
    setData(roaringData);
    if (juridical === "c") {
      setCompany(roaringData.company.orgnr);
    }
  };

  useEffect(() => {
    if (confirmed === "nej") {
      setData(false);
      setConfirmed(null);
    }
  }, [confirmed]);

  const handleSelect = (par1, par2, par3, event) => {
    setConfirmed(event.target.value);
  };

  // Bank Id
  const [openModal, setOpenModal] = useState(false);
  const [ssn, setSsn] = useState('');
  const [ssnError, setSsnError] = useState(false);

  const submit = () => {

    let error = false;

    if (ssnValidation(ssn)) {
      setSsnError('Invalid ssn');
      error = true;
    } else {
      setSsnError(false);
    }

    if (error) return;

    setOpenModal(true);
  };

  const bankIdCallback = (response) => {
    submitUser(response);
  };

  const handleAbort = () => {
    setOpenModal(false);
  };

  const sanitizeOnBlur = useCallback((event) => {
    const value = event.target.value;
    setSsn(value);
    setSsnError(ssnValidation(value));
  }, [setSsn, setSsnError]);

  return (
    <Main
      className="neutral-2"
      center={true}>
      <Wrapper stretch className="secondary">
        <Wrapper noBg tight>
          <Columns className="s-left s-right">
            <Column s="6">
              <img src="/images/tof_registration_hero.png" alt="Registration intro" />
            </Column>
            <Column s="6">
              <Heading size="1" className="s-top-lg">
                {__("tof_registration_intro_hero_title")}
              </Heading>
              <p>{__("tof_registration_intro_hero_text")}</p>
            </Column>
          </Columns>
        </Wrapper>
      </Wrapper>
      {juridical === "c" &&
        <Wrapper stretch tight className="s-top-m-womq">
          <section className="section">
            <Heading size="4" className="f-mono-weight-light">
              Fyll i ditt egna bolags organisationsnummer
            </Heading>
            <CompanyRoaring
              callback={handleRoaringResponse}
              reset={confirmed}
            >
            </CompanyRoaring>
            {data &&
              <>
                <AnimatePresence>
                  <motion.div className="p"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <InputAutofill
                      label={__('company_name')}
                      paragraph
                      value={data.company.companyName}
                    />
                  </motion.div>
                </AnimatePresence>
                <motion.div layoutId="buttons" className="button__wrap s-top-md centeronmobile">
                  <FormSelect
                    callback={handleSelect}
                    columns
                    error_message="Some error message"
                    id="test-select"
                    options={[
                      {
                        id: 'ja',
                        title: 'Ja',
                        value: true
                      },
                      {
                        id: 'nej',
                        title: 'Nej',
                        value: false
                      }
                    ]}
                    optionsState={{
                      error: '',
                      required: true,
                      type: 'select',
                      validation: 'none',
                      value: {
                        ja: {
                          id: 'ja',
                          title: 'Ja',
                          value: confirmed === "ja"
                        },
                        nej: {
                          id: 'nej',
                          title: 'Nej',
                          value: confirmed === "nej"
                        }
                      }
                    }}
                    title="Stämmer bolagsnamnet och organisationsnummret?"
                  />
                </motion.div>
              </>
            }
          </section>
        </Wrapper>
      }
      <Wrapper stretch tight>
        <section className="section">
          <Heading size="4" className="f-mono-weight-light section-padding">
            Vänligen läs igenom villkoren innan du går vidare.
          </Heading>
          <DownloadLink
            icon="pdf"
            className="section-padding"
            name="Hantering av Personuppgifter - Hubins v1.3.pdf"
            path="/assets/hubins_logo.svg"
            secured={false}
          />
          <Checkbox
            className="section-padding"
            optionsState={{
              error: approves.approves.error,
              value: approves.approves.value,
            }}
            required
            label="Jag har läst, förstått och godkänner behandling av mina personuppgifter för de syften som framgår av Hubins AB:s dokument för hantering av personnuppgifter. Jag godtar att motta information via den e-postadress som anges. "
            id="test"
            callback={(value, error) =>
              setApproves({
                'approves': {
                  value: value,
                  error: error,
                }
              })
            }
          />
        </section>
      </Wrapper>
      <Wrapper stretch className="s-top-m-womq">
        <WrapperContent>
          <Heading element="h3" size="4">
            Identifiera dig
          </Heading>
          <div className="box-padding">
            <Input
              onBlur={sanitizeOnBlur}
              required
              placeholder={__reg("ssn_placeholder")}
              type="text"
              label={__reg("ssn")}
              error={ssnError}
              value={ssn}
              controlled
              callback={(value) => {
                setSsn(value);
                setSsnError(ssnValidation(value));
              }}
            />
          </div>
          <div className="button__wrap">
            <Button
              disabled={!approves.approves.value}
              className="cta"
              icon="bankid"
              label="BankID"
              iconSize={16}
              onClick={submit}
            >
              Logga in
            </Button>
          </div>

          {openModal &&
            <BankIDComponent
              initBody={{
                ssn,
                isTest: bypass(),
                process: 'register',
              }}
              hubins
              initEndpoint="/bauth/init"
              statusEndpoint={BANKID_V6 ? "/bauth/qrcode" : "/bauth/status"}
              callback={bankIdCallback}
              abortCallback={handleAbort}
            />
          }
        </WrapperContent>
      </Wrapper>
    </Main>
  );
};

export default Terms;
