import React, { useState } from 'react';
import { __, __route } from "localisation";
import Illustration from 'components/Illustration';
import Img from 'assets/illustrations/hand_thumbs_up.svg';
import Heading from '@hubins/components/Heading';
import { Column, Columns } from 'components/Columns';
import Icon from '@hubins/components/IconNew';
import Button from '@hubins/components/Button';
import Modal from '@hubins/components/Modal';


const PremiumModals = ({
  // exceedsLimit modal
  exceedsLimit,
  setExceedsLimit,
  tradeOrderId,
  extId,
  portfolioUuid,
  portfolio,
  // AlreadyHasPremiums modal
  setAlreadyHasPreemiums,
  alreadyHasPreemiums,
  // SOF modal
  urlParams,
}) => {
  const [sofSuccess, setSofSuccess] = useState(urlParams.includes('success'));

  if (sofSuccess) {
    return <Modal
      open
      contentClass="body-big"
    >
      <Columns>
        <Column s="4">
          <Illustration src={Img} alt="Example Illustration" bg="#02064C" />
        </Column>
        <Column s="8">
          <br />
          <Heading size="4">
            {__('sof_success_modal_title')}
          </Heading>
          <p className="modal__content__text">
            {__('sof_success_modal_text')}
          </p>
        </Column>
        <Column s="12">
          <div className="button__wrap">
            <Button
              className="cta spacing"
              onClick={(e) => {
                e.preventDefault();
                setSofSuccess(false);
              }}
            >
              {__('close')}
            </Button>
          </div>
        </Column>
      </Columns>
    </Modal>;
  }

  if (exceedsLimit) {

    const policyHolder = portfolio ? portfolio.policy_holder[0] : false;
    const isCompany = policyHolder && policyHolder.type === 'COMPANY';

    return (
      <Modal
        open
        contentClass="body-big"
      >
        <Icon icon="Sign warning" size={24} color="primary" className="s-bottom-m" />
        <p className="modal__content__text">
          {__('exceeded_desc')}
        </p>
        <div className="button__wrap">
          <Button
            className="cta spacing"
            href={
              __route(
                isCompany
                  ? 'companySof'
                  : 'sof'
              ) + `/${tradeOrderId}/${extId}/${portfolioUuid}`}
          >
            {__('go_to_sof')}
          </Button>
          <Button className="spacing" onClick={() => {
            setExceedsLimit(false);
          }
          }>
            {__('do_later')}
          </Button>
        </div>
      </Modal>
    );
  }

  if (alreadyHasPreemiums) {
    return (
      <Modal
        open
        contentClass="body-big"
      >
        <Icon icon={'Sign Warning'} size={24} color="primary" className="s-bottom-m" />
        <p className="modal__content__text">
          {__('too_many_premiums')}
        </p>
        <div className="button__wrap">
          <Button
            className="cta spacing"
            href={__route('root')}
          >
            {__('to_overview')}
          </Button>
          <Button className="spacing" onClick={() => {
            setAlreadyHasPreemiums(false);
          }}>
            {__('close')}
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <></>
  );

};

export default PremiumModals;
