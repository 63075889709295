import React, { useState, useEffect, Fragment } from "react";
import { Redirect } from "react-router-dom";
import BankID from 'modules/BankID';
import BankIDV6 from "modules/BankID/BankIDV6";
import { useFeature } from '@growthbook/growthbook-react';
import { __, __route } from "localisation";
// import { getMarket } from "helpers/market";
import { getCurrency } from "helpers";
import { getMoney, scrollTo, formatMoney, triggerScrollToTop } from "helpers";
import AccountSelector from '@hubins/components/AccountSelector';


import generatePDFData from './generatePdfData';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import LoadingPage from '@hubins/components/LoadingPage';

// State
import { connect } from "react-redux";

import { alertsOperations } from '../../state/ducks/alerts';

import DepositAndWithdrawalChart from 'components/Charts/ChartPie/DepositAndWithdrawalChart';
import {
  depositChartData,
  withdrawalChartData,
  getPremiumDropdownTableValues,
  getWithdrawalDropdownTableValues
} from 'components/DropDownEffect/dropDownCalculator';
import { bypass } from 'states/utilities';

import DepositTable from 'modules/Tables/DepositTable';
import PremiumModals from './PremiumModals';
import NoPortfolios from '../NoPortfolios';
import { useReportContext } from '@hubins/components/ReportContext';
import { usePortfolioStats } from 'queries/portfolios';
import Wrapper from '@hubins/components/Wrapper';
import { NEW_getAnswerPromise } from 'helpers/form';

import middleman from 'helpers/middleman';
import Icon from '@hubins/components/IconNew';
import Input from '@hubins/components/Input';
import InputCurrency from '@hubins/components/Input/Currency';
import { validateNumber } from 'components/Validation';

import Accordeon from '@hubins/components/Accordeon';
import Checkbox from 'components/Checkbox';
import { Columns, Column } from 'components/Columns';
import InputAutofill from 'components/InputAutofill';
import SelectDropdown from 'components/SelectDropdown';
import { validateText, validateSelect } from 'components/Validation';

import Button from '@hubins/components/Button';
import Heading from '@hubins/components/Heading';


const DepositAndWithdrawal = ({
  updateAlerts,
  premium = false,
  withdrawal = false,
  location,
}) => {
  const {
    ready,
    portfolios,
    setPortfolioUuid,
    portfolioUuid,
    noPortfolios,
    portfolio,
  } = useReportContext();

  const { data: portfolioStats, isLoading: portfolioStatsLoading } = usePortfolioStats(portfolioUuid);
  const [tradeOrderData, setTradeOrderData] = useState(false);
  const [alreadyHasPreemiums, setAlreadyHasPreemiums] = useState(false);
  const [amount, setAmount] = useState({
    value: '',
    error: false
  });

  const [withdrawalReason, setWithdrawalReason] = useState({
    value: "",
    error: false
  });

  const [withdrawalReasonOtherText, setWithdrawalReasonOtherText] = useState({
    value: "",
    error: false
  });

  const [requiredChecbox, setRequiredCheckbox] = useState({
    value: false,
    error: false
  });
  const [requiredChecbox2, setRequiredCheckbox2] = useState({
    value: false,
    error: false
  });

  const [sending, setSending] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [exceedsLimit, setExceedsLimit] = useState(false);
  const [extId, setExtId] = useState(false);
  const [alert, setAlert] = useState(false);
  const [tradeOrderId, setTradeOrderId] = useState(false);
  const [accountNumber, setAccountNumber] = useState(false);
  const [clearingNumber, setClearingNumber] = useState(false);
  const [bankName, setBankName] = useState(false);

  const BANKID_V6 = useFeature('bankid-api-v6').on;

  const BankIDComponent = BANKID_V6 ? BankIDV6 : BankID;
  // const [accountNumber, setAccountNumber] = useState(false);

  const fetchLatestInsuranceResponse = async () => {
    const policyHolders = portfolio.policy_holder;
    const policyHolder = policyHolders && policyHolders.length > 0 ? policyHolders[0] : false;
    if (policyHolder) {
      setAccountNumber(false);
      setClearingNumber(false);
      setBankName(false);
      const isCompany = policyHolder.type === 'COMPANY';
      const prefix = isCompany ? 'c_' : 'p_';
      const formKey = `${prefix}ins`;
      const res = await NEW_getAnswerPromise(formKey, false, undefined, portfolioUuid);
      const response = res.data;
      try {
        if (response && response.response) {
          const accountNumberKey = isCompany ? 'company_accountnumber' : 'accountnumber';
          if (response.response[accountNumberKey]) {
            setAccountNumber(response.response[accountNumberKey]);
          }
          const clearingNumberKey = isCompany ? 'company_clearing' : 'clearing';
          if (response.response[clearingNumberKey]) {
            setClearingNumber(response.response[clearingNumberKey]);
          }
          const bankNameKey = isCompany ? 'company_bankname' : 'bankname';
          if (response.response[bankNameKey] && Array.isArray(response.response[bankNameKey])) {
            let theBankName = response.response[bankNameKey][0] || false;
            if (theBankName === 'other') {
              theBankName = response.response['other_bankname'];
            }
            if (theBankName) {
              setBankName(response.response[bankNameKey]);
            }
          }
        }
      } catch (e) {
        console.error(e.message);
      }

    }
  };

  useEffect(() => {
    if (withdrawal && portfolio) {
      fetchLatestInsuranceResponse();
    }
  }, [withdrawal, portfolio]);

  useEffect(() => {
    if (alert) {
      updateAlerts({
        id: 'deposit_withdrawal_fail',
        content: <>{__("standard_error_message", { email: __('platform_email'), tel: __('platform_tel') })}</>,
      });
      setAlert(false);
    }
  }, [alert]);

  useEffect(() => {
    if (exceedsLimit && submitted) {
      setSubmitted(false);
      setSending(false);
      setTradeOrderData(false);
    }
  }, [exceedsLimit, submitted]);

  if (!ready) {
    return <LoadingPage />;
  }

  const maxWithdrawal = portfolio ? (portfolio.available_balance - 0.005 * (portfolio.market_value - portfolio.available_balance)) / 1.0015 : 0;
  const maxAmount = maxWithdrawal > 0 ? maxWithdrawal : 0;

  const validateForm = () => {
    let errors = false;

    // Amount
    if (
      amount.error ||
      validateText(amount.value, true)
    ) {
      setAmount({ ...amount, error: __("error_empty") });
      errors = true;
    }

    // Demand Assesment
    if (
      (requiredChecbox.error ||
        validateText(requiredChecbox.value, true))
    ) {
      setRequiredCheckbox({ ...requiredChecbox, error: __("error_empty") });
      errors = true;
    }
    // Source of funds
    if (
      premium && (requiredChecbox2.error ||
        validateText(requiredChecbox2.value, true))
    ) {
      setRequiredCheckbox2({ ...requiredChecbox2, error: __("error_empty") });
      errors = true;
    }

    if (withdrawal) {
      if (
        withdrawalReason.error ||
        validateSelect(withdrawalReason.value, true)
      ) {
        setWithdrawalReason({ ...withdrawalReason, error: __("error_options") });
        errors = true;
      }

      if (
        withdrawalReason.value === "withdrawal_reason_other" &&
        validateText(withdrawalReasonOtherText.value, true)
      ) {
        setWithdrawalReasonOtherText({ ...withdrawalReasonOtherText, error: __("error_empty") });
        errors = true;
      }
    }

    if (errors) {
      scrollTo(200);
      return false;
    }
    return true;
  };

  const sendData = async () => {

    try {
      let valid = validateForm();
      if (!valid) {
        setSending(false);
        return false;
      }

      if (premium) {
        const openTradeOrders = await middleman.get(`/v1/fa/tradeorders/open/${portfolioUuid}`).then(res => (
          res.data
        ));

        if (openTradeOrders && Object.keys(openTradeOrders).length > 0) {
          setAlreadyHasPreemiums(true);
          setSending(false);
          return;
        }
      }
      // Send data to FA

      let formatedData = {};
      let withdrawalReasonAnswerTranslated = '';

      if (premium) {
        formatedData = {
          "o.type": "PRE",
          "o.account": "AUTO",
          "o.currency": getCurrency(),
          "o.amount": amount.value,
          "o.unitPrice": "1",
          "o.tradeAmount": amount.value,
          "o.tags": `MoneyRequest,Trade Order source-JIP,`
        };
      }
      if (withdrawal) {
        const withdrawalReasonAnswer =
          withdrawalReason.value === "withdrawal_reason_other"
            ? withdrawalReasonOtherText.value
            : withdrawalReason.value;

        withdrawalReasonAnswerTranslated =
          withdrawalReason.value === "withdrawal_reason_other"
            ? withdrawalReasonOtherText.value
            : __(withdrawalReason.value);

        formatedData = {
          "tr.parentPortfolio": portfolio.short_name,
          "tr.type": "WD",
          "tr.status": "OK",
          "tr.security": "",
          "tr.account": portfolio.account_number,
          "tr.currency": portfolio.currency,
          "tr.amount": amount.value,
          "tr.unitPrice": "1",
          "tr.tradeAmount": amount.value, // amount + cost
          "tr.tags": `Trade Order source-JIP,Payment-pending,Reason for withdrawal: ${__(
            withdrawalReasonAnswer
          )}`
        };
      }

      formatedData.pdfData = generatePDFData(premium, amount.value, withdrawalReasonAnswerTranslated);
      setTradeOrderData(formatedData);
    } catch (e) {
      console.error(e.message);
      setSending(false);
      setAlert(true);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const formattedValue = Number(amount.value.replace(/\s/g, ''));
    if (formattedValue < 1) {
      setAmount({
        value: amount.value,
        error: 'Värdet måste vara större än 0'
      });
      triggerScrollToTop(true);
    }
    else if (withdrawal && formattedValue > maxAmount) {
      setAmount({
        value: amount.value,
        error: `Värdet får inte överstiga ${maxAmount}`
      });
      triggerScrollToTop(true);
    }
    else {
      sendData();
      setSending(true);
    }
  };



  const premiumForm = () => {
    const tableData = getPremiumDropdownTableValues(portfolio.fees, amount.value);
    return (
      <>
        <Columns>
          <Column xs="12">
            <Heading className="c-primary" size="3">{__("new_deposit")}</Heading>
          </Column>
          <Column l="6">
            <Heading element="h3" size="4" className="s-bottom-m">
              Välj konto
            </Heading>
            <AccountSelector
              portfolios={portfolios}
              setPortfolioUuid={setPortfolioUuid}
              portfolioUuid={portfolioUuid}
            />
          </Column>
          <Column l="6">
            <Heading element="h3" size="4" className="s-bottom-m">
              Belopp
            </Heading>
            <InputCurrency
              value={amount.value}
              error={amount.error}
              min={0}
              label={__("trade_amount") + " " + getCurrency()}
              required
              callback={(value) => {
                setAmount({ value, error: validateNumber(value, true, 0) });
              }}
            />
          </Column>

          <Column l="6" bg="primary" box="medium" className="flex">
            <Heading element="h3" size="4">{__('demand_assesment')} & {__('sof')}</Heading>
            <div className="s-top-m">
              <Checkbox
                inverted
                label={__("demand_assesment_checkbox")}
                id="required_checbox"
                optionsState={{
                  error: requiredChecbox.error,
                  value: requiredChecbox.value
                }}
                required
                callback={(value, error) =>
                  setRequiredCheckbox({ value, error })
                }
              />
            </div>
            <div className="s-top-m">
              <Checkbox
                inverted
                label={__("sof_checkbox")}
                id="required_checbox"
                optionsState={{
                  error: requiredChecbox2.error,
                  value: requiredChecbox2.value
                }}
                required
                callback={(value, error) =>
                  setRequiredCheckbox2({ value, error })
                }
              />
            </div>
            {__('sof_and_da_changed')}
          </Column>

          <Column s="12" l="6" bg="secondary" box="medium">
            {portfolioStatsLoading ? (
              <LoadingBox />
            ) : (
              <>
                {/* <Heading element="h3" size="4" >Deklarerat belopp</Heading>
                <Heading size="3" className="s-top-m">{formatMoney(portfolioStats.limit, 'SEK', 0)}</Heading> */}
                <div className="s-top-sm">
                  <div className="progress-row body-small">
                    <p>Insättning hittills</p>
                    <p style={{ marginTop: '0' }}><strong>{formatMoney(portfolioStats.deposits, 'SEK', 0)}</strong></p>
                  </div>
                  {/* <ProgressBar progress={(portfolioStats.deposits / portfolioStats.limit) * 100} /> */}
                </div>
              </>
            )}
          </Column>
          <Column s="12">
            <Heading size="4" className="c-primary">
              Avgifter
            </Heading>
            <p>Hur påverkas kontantpremien av ett års försäkringsavgifter?</p>
            <Fragment key="deposit">
              <DepositAndWithdrawalChart data={depositChartData(portfolio.fees, 100000)} />
            </Fragment>
            <Heading size="5" className="c-primary">
              Försäkringsavgifter
            </Heading>
            <DepositTable
              data={tableData.insurance}
            />
            <Heading size="5" className="s-top-m c-primary">
              Skatter
            </Heading>
            <DepositTable
              data={tableData.tax}
            />
          </Column>
          <Column s="12">
            <Heading className="c-primary" element="h3" size="4">FAQ</Heading>
          </Column>
          <Column m="6" l="6">
            <Accordeon label={__("faq_q1")}>
              <div className="section-padding-small">
                <p>{__("faq_a1")}</p>
              </div>
            </Accordeon>
          </Column>
          <Column m="6" l="6">
            <Accordeon label={__("deposit_disclaimer_title")}>
              <div className="section-padding-small">
                {__("deposit_disclaimer")}
              </div>
            </Accordeon>
          </Column>
        </Columns>
      </>
    );
  };

  const withdrawalForm = () => {
    const tableData = getWithdrawalDropdownTableValues(portfolio.fees, amount.value);
    return (
      <>
        <Columns>
          <Column xs="12">
            <Heading size="3" className="c-primary">{__("new_withdrawal")}</Heading>
          </Column>
          <Column l="6">
            <Heading element="h3" size="4">{__('choose_account')}</Heading>
            <AccountSelector
              portfolios={portfolios}
              setPortfolioUuid={setPortfolioUuid}
              portfolioUuid={portfolioUuid}
            />
          </Column>

          <Column l="6">
            <Heading element="h3" size="4">{__('trade_amount')}</Heading>
            <InputCurrency
              value={amount.value}
              error={amount.error}
              min={1}
              max={maxAmount}
              label={__("trade_amount") + " " + getCurrency()}
              required
              callback={(value) => {
                setAmount({ value, error: validateNumber(value, true, 1, maxAmount) });
              }}
            />
            <InputAutofill
              className="s-top-l"
              label={__("available_for_withdrawal")}
              value={getMoney(maxAmount)}
            />
            <InputAutofill
              className="s-top-l"
              label="Till konto"
              value={(clearingNumber && accountNumber) ? `${clearingNumber} - ${accountNumber}` : undefined}
            />
            <InputAutofill
              className="s-top-l"
              label="Bank (BIC)"
              value={bankName}
            />
            <Heading className="s-top-xl" element="h3" size="4">{__('confirm_withdrawal')}</Heading>
            <SelectDropdown
              label={__("withdrawal_reason")}
              value="false"
              error={withdrawalReason.error}
              required
              onChange={(value, error) =>
                setWithdrawalReason({ value, error })
              }
            >
              <option value="false" disabled>
                {__("error_options")}
              </option>

              <option value="withdrawal_reason_investment">
                {__("withdrawal_reason_investment")}
              </option>

              <option value="withdrawal_reason_saving_in_institute">
                {__("withdrawal_reason_saving_in_institute")}
              </option>

              <option value="withdrawal_reason_consumption">
                {__("withdrawal_reason_consumption")}
              </option>

              <option value="withdrawal_reason_other">
                {__("withdrawal_reason_other")}
              </option>
            </SelectDropdown>

            {withdrawalReason.value === "withdrawal_reason_other" &&
              (
                <Input
                  value={withdrawalReasonOtherText.value}
                  error={withdrawalReasonOtherText.error}
                  label={__("withdrawal_reason_text")}
                  validate
                  required
                  callback={(value) => {
                    setWithdrawalReasonOtherText({ value, error: validateText(value, true) });
                  }}
                />
              )}
            <div className="s-top-m">
              <Checkbox
                label={__("demand_assesment_checkbox")}
                id="required_checbox"
                optionsState={{
                  error: requiredChecbox.error,
                  value: requiredChecbox.value
                }}
                required
                callback={(value, error) =>
                  setRequiredCheckbox({ value, error })
                }
              />
            </div>
            {__('sof_and_da_changed')}
          </Column>
          <Column s="12">
            <Heading size="4" className="c-primary">
              Avgifter och ersättningar
            </Heading>
            <p>
              Hur påverkas utbetalningen av uttagsavgifter?
            </p>
            <Fragment key="withdrawal">
              <DepositAndWithdrawalChart data={withdrawalChartData()} />
            </Fragment>
            <Heading size="5" className="c-primary">
              Försäkringsavgifter
            </Heading>
            <p>Pågående löpande avgifter på kontanter i försäkringen.</p>
            <DepositTable
              data={tableData.insurance}
            />
            <Heading size="5" className="s-top-m c-primary">Skatter</Heading>
            <p>Det kostar inget att göra ett uttag och stämpelskatten utgör ingen extra kostnad då kapitalförsäkringsskatten kan nedsättas med motsvarande belopp.*</p>
            <DepositTable
              data={tableData.tax}
            />
            <Heading size="5" className="s-top-m c-primary">Belopp att utbetala</Heading>
            <DepositTable
              data={tableData.total}
            />
          </Column>
          <Column s="12">
            <Heading className="c-primary" element="h3" size="4">FAQ</Heading>
          </Column>
          <Column m="12" l="12">
            <Accordeon label={__("withdrawal_disclaimer_title")}>
              <div className="section-padding-small">
                {__("withdrawal_disclaimer")}
              </div>
            </Accordeon>
          </Column>
        </Columns>
      </>
    );
  };

  const checkIfExceedsLimit = (data) => {
    return data.tagList.includes('SourceOfFunds-needed');
  };

  const bankIdCallback = (res) => {
    if (premium) {
      if (checkIfExceedsLimit(res)) {
        setExceedsLimit(true);
        setExtId(res.extId);
      }
    }
    setTradeOrderId(res.transactionId);
    setSubmitted(true);
  };

  if (premium && submitted && !exceedsLimit) {
    return <Redirect
      push
      to={`${__route("depositInstructions")}/q/${portfolioUuid}/${tradeOrderId}`}
    />;
  }

  if (
    withdrawal && submitted
  ) {
    return <Redirect push to={__route("withdrawalSuccess")} />;
  }


  if (noPortfolios) {
    return <NoPortfolios />;
  }

  return (
    <Wrapper>
      {premium && (
        <PremiumModals
          setAlreadyHasPreemiums={setAlreadyHasPreemiums}
          alreadyHasPreemiums={alreadyHasPreemiums}
          exceedsLimit={exceedsLimit}
          setExceedsLimit={setExceedsLimit}
          tradeOrderId={tradeOrderId}
          portfolio={portfolio}
          extId={extId}
          urlParams={location.search}
          portfolioUuid={portfolioUuid}
        />
      )}
      {tradeOrderData && (
        <BankIDComponent
          initBody={{
            order: tradeOrderData,
            isTest: bypass(),
          }}
          initEndpoint={`/tradeorder/init/${portfolioUuid}`}
          statusEndpoint={BANKID_V6 ? `/tradeorder/qrcode/${portfolioUuid}` : `/tradeorder/status/${portfolioUuid}`}
          callback={bankIdCallback}
          abortCallback={() => {
            setTradeOrderData(false);
            setSending(false);
          }}
          hubins
        />
      )}
      <form
        onSubmit={e => submitForm(e)}
      >
        {premium
          ? premiumForm()
          : withdrawalForm()}

        <div>
          <section className="center section-padding">
            <Button
              type="submit"
              className="cta"
              loading={sending}
            >
              {premium
                ? __("conducts_deposit")
                : __("conduct_withdrawal")}
            </Button>
          </section>
        </div>
      </form>

      <Icon icon="Information Circle by Streamlinehq" size="16" className="dualbox__icon" />
      {withdrawal && (
        <section className="section section--wide white">
          <Heading element="h3" size="4" className="c-primary">{__("wd_info_header")}</Heading>
          <p>{__("wd_info_text")}</p>
        </section>
      )}
    </Wrapper>
  );
};

const mapDispatchToProps = {
  updateAlerts: alertsOperations.updateAlerts,
};

export default connect(
  null,
  mapDispatchToProps,
  null
)(DepositAndWithdrawal);
