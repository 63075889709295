import React from 'react';
import './index.scss';
import { __, __route } from "localisation";
import { Columns, Column } from 'components/Columns';
import { getMoney } from 'helpers';

import { LoadingBar } from '@hubins/components/LoadingIndicators';
import Button from '@hubins/components/Button';
import Heading from '@hubins/components/Heading';
import Summary from 'components/Summary';
import { useTradeOrders } from 'queries/tradeOrders';
import Icon from '@hubins/components/IconNew';

const marketBanks = {
  "SE": [
    {
      link: "https://www.seb.se/",
      name: "SEB",
      path: "/images/banks/sweden/seb.svg",
    },
    {
      link: "https://www.nordea.se/",
      name: "Nordea",
      path: "/images/banks/sweden/nordea.svg",
    },
    {
      link: "https://www.danskebank.se/",
      name: "Danske Bank",
      path: "/images/banks/sweden/danskebank.svg",
    },
    {
      link: "https://www.sbab.se/",
      name: "SBAB",
      path: "/images/banks/sweden/sbab.svg",
    },
    {
      link: "https://www.handelsbanken.se/",
      name: "Handelsbanken",
      path: "/images/banks/sweden/handelsbanken.svg",
    },
    {
      link: "https://www.lansforsakringar.se/",
      name: "Lansförsäkringar",
      path: "/images/banks/sweden/lansforsakringar.svg",
    },
    {
      link: "https://www.swedbank.se/",
      name: "Swedbank",
      path: "/images/banks/sweden/swedbank.png",
    }
  ],
  "NO": [
    {
      link: "https://www.bnbank.no/",
      name: "BN Bank",
      path: "/images/banks/norway/bnbank.png",
    },
    {
      link: "https://www.danskebank.no/",
      name: "Danske Bank",
      path: "/images/banks/norway/danskebank.svg",
    },
    {
      link: "https://www.dnb.no/",
      name: "DNB",
      path: "/images/banks/norway/dnb.svg",
    },
    {
      link: "https://www.handelsbanken.no/",
      name: "Handelsbanken",
      path: "/images/banks/norway/handelsbanken.svg",
    },
    {
      link: "https://www.nordea.no/",
      name: "Nordea",
      path: "/images/banks/norway/nordea.svg",
    },
    {
      link: "https://www.sbanken.no/",
      name: "SBanken",
      path: "/images/banks/norway/sbanken.svg",
    },
    {
      link: "https://www.seb.no/",
      name: "SEB",
      path: "/images/banks/norway/seb.svg",
    },
    {
      link: "https://www.sparebank1.no/",
      name: "SpareBank",
      path: "/images/banks/norway/sparebank.svg",
    }
  ],
  "FI": [
    {
      link: "https://www.aktia.fi/",
      name: "Aktia",
      path: "/images/banks/finland/aktia.svg",
    },
    {
      link: "https://www.alandsbanken.fi/",
      name: "Alandsbanken",
      path: "/images/banks/finland/alandsbanken.svg",
    },
    {
      link: "https://www.danskebank.fi/",
      name: "Danske Bank",
      path: "/images/banks/finland/danskebank.svg",
    },
    {
      link: "https://www.handelsbanken.fi/",
      name: "Handelsbanken",
      path: "/images/banks/finland/handelsbanken.svg",
    },
    {
      link: "https://www.nordea.fi/",
      name: "Nordea",
      path: "/images/banks/finland/nordea.svg",
    },
    {
      link: "https://www.op.fi/",
      name: "OP Financial Group",
      path: "/images/banks/finland/opfinancialgroup.svg",
    }

  ],
};

// Ex usage url: http://localhost:8888/deposit/instructions/q/4608/271674
const TransferInstructions = ({ match }) => {

  const { portfolioUuid, orderId } = match.params;

  // const { data: portfolios, isLoading, isFetched } = usePortfolios(national_identity_number);
  const { data: tradeOrders, isLoading: tradeOrdersLoading, isFetched: tradeOrdersFetched } = useTradeOrders(portfolioUuid);

  // const portfolio = (!isLoading && isFetched) ? portfolios?.find((item) => item.uuid === portfolioUuid) : false;
  const tradeOrder = (!tradeOrdersLoading && tradeOrdersFetched) ? tradeOrders?.find((item) => item.id === Number(orderId)) : false;
  const referenceNumber = (tradeOrder && tradeOrder.parentPortfolio) ? tradeOrder.parentPortfolio.shortName : <LoadingBar small />;


  const amount = tradeOrder ? tradeOrder.tradeAmount : false;

  const market = 'SE';

  return (
    <>
      <section className="section section--wide relative white">
        <Columns>
          <Column s="12">
            <Heading size="3">{__("new_deposit")}</Heading>
          </Column>

          <Column s="12" m="8" offsetM="2" l="6" offsetL="3">
            <Heading size="3" className="s-top-l">{__('payment_instructions')}</Heading>
          </Column>

          <Column s="12" m="8" offsetM="2" l="6" offsetL="3" box="medium" bg="primary">
            <Heading element="h3" size="4">{__('bank_details')}:</Heading>
            {market === "SE" && (
              <p className="body-big">
                <strong>{__('bankgiro')}</strong><br />
                {__('bankgiro_number')}: <strong className="select-all">556-3614</strong><br />
                {__('payment_reference')}: <strong className="select-all">{referenceNumber}</strong><br />
              </p>
            )}
            {market !== "SE" && (
              <p className="body-big">
                <strong>{__('bank_transfer')}</strong><br />
                {__('bank')}: <strong className="select-all">{market === "NO" ? ("DNB") : ("FINSKA")}</strong><br />
                {__('account_number')}: <strong className="select-all">{market === "NO" ? ("15039421248") : ("FINSKA")}</strong><br />
                {__('payment_reference')}: <strong className="select-all">{referenceNumber}</strong><br />
              </p>
            )}
            <Summary
              title={__('payment_amount')}
              value={amount ? getMoney(amount) : <LoadingBar small />}
              textSize="body-big"
            />
          </Column>
        </Columns>

        <div className="center section-padding">
          <Button href={__route('root')} className="cta withicon withicon--left" iconLeft icon="arrow left 1" iconColor='white'>
            {__('my_accounts')}
          </Button>
        </div>
      </section>

      <section className="section section--wide relative neutral-3">
        <Columns>
          <Column s="12">
            <Heading element="h3" size="4">{__('list_of_banks_text')}</Heading>
          </Column>
          {marketBanks[market].map((obj, index) => (
            <Column xs="6" m="3" key={index} box bg="white">
              <div className="bank-container">
                <a href={obj.link} target="_blank" rel="noopener noreferrer">
                  <img src={obj.path} alt={obj.name} />
                </a>
              </div>
            </Column>
          ))}
        </Columns>
      </section>
    </>
  );
};

export default TransferInstructions;
