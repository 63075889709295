import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@hubins/components/Heading';
import './index.scss';


const Summary = (props) => {
  return (
    <div className="summary">
      <Heading size={5} element="h4" className={`summary__title`}>
        <span>{props.title}:</span>
        <strong className={props.error ? 'c-danger' : undefined}>{props.value}</strong>
      </Heading>
      {props.description &&
        <p className="summary__description"><span>{props.description}</span></p>
      }
      {props.metaTitle &&
        <p className="summary__meta"><span>{props.metaTitle}:</span><span>{props.metaValue}</span></p>
      }
    </div>
  );
};

Summary.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  metaTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  metaValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
};

Summary.defaultProps = {
  title: 'Default title',
  error: undefined,
};

export default Summary;
