import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authOperations } from '../../state/ducks/auth';
import { __ } from 'localisation';
import { motion } from 'framer-motion';
import Heading from '@hubins/components/Heading';

class LoggedOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  redirect() {
    this.props.logoutUser();

    this.timer = setTimeout(function () {
      this.setState({ redirect: true });
    }.bind(this), this.props.timer);
  }

  componentDidMount() {
    this.redirect();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return (
      <main className="main main--center" id="MainContent" data-shiftonsidebaropen>
        <motion.div
          className="wrapper"
          initial={{ y: 20 }}
          animate={{ y: 0 }}
        >
          <section className="section center">
            <img src="/illustrations/login.svg" alt="A locked lock" className="illustration illustration--medium" />
            <Heading size="3">{__('logout_title')}</Heading>
            <p>{__('logout_description')}</p>
            {/* {this.state.redirect && <Redirect to={__route('root')} />} */}
          </section>
        </motion.div>
      </main>

    );
  }
}

LoggedOut.defaultProps = {
  timer: 2000
};


export default connect(null, { logoutUser: authOperations.logoutUser })(LoggedOut);
