import React from 'react';
import __, { __route } from 'localisation';
import { Columns, Column } from 'components/Columns';
import Wrapper from '@hubins/components/Wrapper';
import Heading from '@hubins/components/Heading';
import Button from '@hubins/components/Button';
import Box from 'components/Box';
import onboardingImgUrl from '../../assets/illustrations/Onboarding.svg';
import UnfinishedRegistrationsList from '@hubins/components/UnfinishedRegistrationsList';

const EmptyReport = () => {

  return (
    <Wrapper>
      <Columns>
        <Column m="6">
          <Heading size="1" className="s-bottom-l">
            {__('welcome_text')}
          </Heading>
          <Heading size="5" className='s-bottom-xxl'>
            {__('almost_done_registration')}
          </Heading>
          <UnfinishedRegistrationsList />
          <Box bg="secondary" borderRadius={false}>
            <Heading size="2">
              {__('register_insurance_title_new')}
            </Heading>
            <Heading size="5" className="s-bottom-l">
              {__('start_registration_desc')}
            </Heading>
            <Button icon="arrow right 1" size="large" iconColor='white' href={__route('register_insurance')} className="cta">
              Läs mer
            </Button>
          </Box>
          <Box bg="primary" borderRadius={false}>
            <Heading size="2" className="c-secondary">
              Investeringssidan för onoterat
            </Heading>
            <p className='c-secondary'>Anslagstavlan som ger dig tillgång till unika och annars svåråtkomliga investeringstyper.</p>
            <Button icon="arrow right 1" className='white' size="large" iconColor='primary' href={__route('invest')}>
              Utforska
            </Button>
          </Box>
        </Column>
        <Column m="6">
          <img src={onboardingImgUrl} />
          <Box bg="primary" borderRadius={false}>
            <Heading size="2" className="c-secondary">
              Har du frågor?
            </Heading>
            <Heading size="5" className="s-bottom-l c-secondary">
              Boka gärna ett möte med oss nedan eller kontakta vår kundservice direkt via <a target="_blank" rel="noreferrer" href="mailto:kundservice@hubins.com">kundservice@hubins.com</a> eller på telefonnummer <a target="_blank" rel="noreferrer" href="tel:0317971936">031-797 19 36</a>.
            </Heading>
            <Button icon="arrow right 1" className='white' size="large" iconColor='primary' external href={"https://meetings-eu1.hubspot.com/emil-holm"}>
              Boka möte
            </Button>
          </Box>
        </Column>
      </Columns>
    </Wrapper>
  );

};

export default EmptyReport;
