import React from "react";
import __ from "localisation";
import Button, { ButtonWrap } from "@hubins/components/Button";
import Heading from '@hubins/components/Heading';
import Modal from '@hubins/components/Modal';
import Icon from "@hubins/components/IconNew";

const JuridicalModal = ({ juridicalModalOpen, setJuridicalModalOpen, setJuridical }) => {

  return (
    <Modal
      className="animation-reveal narrow modal__overlay--centermobile"
      contentClass=""
      noWrapper={false}
      open={juridicalModalOpen}
      shouldCloseOnOverlayClick={false}
    >
      <Icon icon="Information Circle by Streamlinehq" size={24} className="s-top-md" />
      <Heading size="3" className="f-mono-weight-light s-top-md">
        {__("tof_intro_investment_type_question")}
      </Heading>
      <p>{__("tof_intro_investment_type_question_text")}</p>
      <ButtonWrap>
        <Button
          className="spacing"
          onClick={() => {
            setJuridical('p');
            setJuridicalModalOpen(!juridicalModalOpen);
          }}
        >
          {__("tof_intro_investment_type_private")}
        </Button>
        <Button
          className="spacing"
          onClick={() => {
            setJuridical('c');
            setJuridicalModalOpen(!juridicalModalOpen);
          }}>
          {__("tof_intro_investment_type_company")}
        </Button>
      </ButtonWrap>
    </Modal>
  );
};

export default JuridicalModal;
