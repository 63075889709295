import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Button from '@hubins/components/Button';
import QRCode from "react-qr-code";
import Heading from '@hubins/components/Heading';
import Icon from '@hubins/components/IconNew';
import HubinsAnim from './HubinsAnim';
import __ from 'localisation';
import './overlay.scss';

import {
  illustrationVariants,
  contentVariants,
} from 'views/LoginFormBankId/animationData.js';

const wrapVariants = {
  hidden: { backgroundColor: 'rgba(2, 6, 76, 0)', display: 'flex', opacity: 1 },
  visible: { backgroundColor: 'rgba(2, 6, 76, 0.5)', display: 'flex', opacity: 1 },
  waiting: { backgroundColor: 'rgba(2, 6, 76, 0.5)', display: 'flex', opacity: 1 },
  error: { backgroundColor: 'rgba(2, 6, 76, 0.5)', display: 'flex', opacity: 1 },
  success: {
    backgroundColor: 'rgba(2, 6, 76, 0)',
    opacity: 0,
    transition: { delay: 0.2, ease: "easeOut", opacity: { delay: 1 }}, // adding a opacity to fake aniimation length to be able to use transitionEnd and set display none on everything
    transitionEnd: {
      display: "none",
    },
  },
};

const bgVariants = {
  hidden: { scale: 0.8, opacity: 0 }, // this is onlu the "from" animation. we don't do an exit animat
  visible: { scale: 1, opacity: 1 },
  waiting: { scale: 1, opacity: 1 },
  error: { scale: 1, opacity: 1 },
  success: { scale: 1.2, opacity: 0, transition: { delay: 0, ease: "easeOut" }},
};

const BankIdModalV6 = ({
  open,
  error,
  platform,
  handleAbort,
  handleSubmit,
  handleErrorSubmit,
  qrCode,
  autoStartToken,
  hasScanned,
}) => {

  const [overlayState, setOverlayState] = useState('hidden');

  useEffect(() => {
    if (error) {
      setOverlayState('error');
    }
  }, [error]);

  useEffect(() => {
    if (open) {
      setOverlayState('visible');
    } else {
      setOverlayState('hidden');
    }
  }, [open]);

  useEffect(() => {
    if (overlayState === 'visible') {
      setOverlayState("waiting");
    }
  }, [overlayState]);

  return (
    <motion.div
      variants={wrapVariants}
      animate={overlayState}
      initial="hidden"
      className={`overlay overlay--${overlayState}`}
    >
      <motion.div className="overlay__content">
        {overlayState !== "hidden" && (
          <>
            <motion.div className="overlay__bg" variants={bgVariants} />
            <motion.button type="button" className="overlay__close button-icon false" onClick={handleAbort} variants={bgVariants}>
              <Icon icon="remove" size={16} label="Close login" color="white" />
            </motion.button>
            <main
              className="main main--center loginform"
              id="MainContent"
              data-shiftonsidebaropen
            >
              <motion.div animate={overlayState} initial="visible" className="loginform__content">
                {qrCode && !hasScanned ? (
                  <div style={{ height: "320px" }}>
                    <QRCode
                      bgColor="#02064C"
                      fgColor="#FFCB94"
                      value={qrCode}
                    />
                  </div>
                ) : (
                  <HubinsAnim
                    animateState={overlayState === 'visible' ? 'waiting' : overlayState} // On signin we dont have a login state, same as waiting
                    variants={illustrationVariants}
                    platform={platform}
                  />
                )}
                {/* The login form */}


                {/* When the user started the login process */}
                <motion.div
                  className="loginform__content"
                  animate={(overlayState === 'waiting' || overlayState === 'visible') ? 'visible' : 'hidden'}
                  variants={contentVariants}
                  onSubmit={handleSubmit}
                  exit="exit"
                >
                  <motion.div>
                    <Heading element="h1" size="3" className="center c-white">{__('bankid_title_new')}</Heading>
                    {hasScanned ? (
                      <p>Skriv in din säkerhetskod i BankID-appen.</p>
                    ) : (
                      <p className="s-bottom-md center  ">{__('bankid_start', { platform: (platform === 'Hubins') ? 'Hubins AB' : 'Quantum Leben AG' })}</p>
                    )}
                  </motion.div>
                  <motion.div className="s-top-md">
                    {autoStartToken && (
                      <motion.a
                        className="button cta inverted withicon withicon--medium"
                        href={`bankid:///?autostarttoken=${autoStartToken}&redirect=null`}
                        target="_blank"
                        rel="noopener noreferrer"
                        // variants={inputVariants}
                      >
                        {__('bankid_title')}
                        <Icon icon="bankid" size={16} color="white" />
                      </motion.a>
                    )}
                    {/* Submit button */}
                    <motion.div
                      className="center"
                    // variants={submitVariants}
                    >
                      <Button className="link spacing inverted" onClick={handleAbort}>
                        {__('bankid_abort')}
                      </Button>
                    </motion.div>
                  </motion.div>
                </motion.div>


              </motion.div>

              {/* If there is an error */}
              <motion.div
                className={`loginform__content loginform__content--over ${overlayState === 'error' ? '' : 'noclicks'}`}
                animate={overlayState === 'error' ? 'visible' : 'hidden'}
                initial="hidden"
                variants={contentVariants}
                onSubmit={handleSubmit}
                exit="exit"
              >
                <Icon
                  // color="c-warning"
                  icon="Sign Warning"
                  size={24}
                  className="s-bottom"
                />

                <Heading element="h2" size={3}>
                  {__('login_failed_message')}
                </Heading>
                <p>
                  {__('bankid_error')}
                </p>

                <Button
                  className="cta inverted withicon"
                  onClick={() => {
                    setOverlayState("waiting");
                    handleErrorSubmit();
                  }}
                >
                  {__('try_again')}
                </Button>
                <Button className="link spacing inverted" onClick={handleAbort}>
                  {__('close')}
                </Button>
              </motion.div>

            </main>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};


BankIdModalV6.defaultProps = {
  open: false,
  platform: 'Hubins',
  error: false,
};

export default BankIdModalV6;
