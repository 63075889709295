import React from "react";
import __ from "localisation";
import Button, { ButtonWrap } from "@hubins/components/Button";
import Heading from '@hubins/components/Heading';
import Modal from '@hubins/components/Modal';
import Icon from "@hubins/components/IconNew";
import Input from '@hubins/components/Input';

const NewInvestmentModal = ({ newInvestmentModalOpen, setNewInvestmentModalOpen, selectedInvestments, setSelectedInvestments, setNamedInvestment, namedInvestment, addToState }) => {

  return (
    <Modal
      className="animation-reveal narrow modal__overlay--centermobile"
      contentClass=""
      callback={() => setNewInvestmentModalOpen(!newInvestmentModalOpen)}
      noWrapper={false}
      open={newInvestmentModalOpen}
      closeButton
    >
      <Icon icon="add" size={24} className="s-top-md" />
      <Heading size="3" className="f-mono-weight-light s-top-md">
        {__("tof_add_investment_modal_title")}
      </Heading>
      <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</p>
      <Input
        className="s-top-md"
        required
        type="text"
        placeholder="Investeringsnamn"
        callback={(value) =>
          setNamedInvestment({
            ...namedInvestment,
            name: {
              value: value,
              error: false,
            },
          })
        }
      />
      <ButtonWrap>
        <Button
          className="spacing withicon"
          onClick={() => {
            const investment = {
              "name": namedInvestment.name.value,
              "type": null,
              "isin": null,
              "status": "new"
            };
            addToState(investment, selectedInvestments, setSelectedInvestments);
            setNewInvestmentModalOpen(!newInvestmentModalOpen);
          }}
        >
          {__("tof_add")}
          <Icon
            anim=""
            ariaHidden={undefined}
            className=""
            fallback="fallback"
            icon="arrow right 1"
            size={16}
            spacing=""
          />
        </Button>
      </ButtonWrap>
    </Modal>
  );
};

export default NewInvestmentModal;
