import React, { useState, useEffect, useRef } from 'react';
import Heading from '@hubins/components/Heading';
import Main from 'components/Main';
import SourceCards from 'components/SourceCards';
import { Columns, Column } from 'components/Columns';
import { LoadingBox } from '@hubins/components/LoadingIndicators';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Modal from '@hubins/components/Modal';
import __ from 'localisation';
// import SourceOfWealthInstructions from '@hubins/components/SourceOfWealthInstructions';
import Tutorial from 'components/Tutorial';
// import SourceOfWealthHighRiskIntro from '@hubins/components/SourceOfWealthHighRiskIntro';
import SourceOfFundsSummary from '@hubins/components/SourceOfFundsSummary';
import { step2 } from './tutorialGuides';

const SourceOfWealth = ({
  transaction,
  sources,
  loading: propsLoading,
  isHighRisk,
  setSources,
  setSelected,
  selected,
  setDisabled,
  innerRef,
  portfolio,
}) => {

  const [showNegativeAmountModal, setShowNegativeAmountModal] = useState(false);
  const sourceAmountKey = isHighRisk ? 'highRiskAvailableAmount' : 'availableAmount';
  const someSourceHasNegativeAmount = useRef(
    sources.some((source) => {
      return source[sourceAmountKey] < 0;
    })
  );

  const tutorialSteps = useRef(step2(isHighRisk, someSourceHasNegativeAmount.current));

  useEffect(() => {
    setDisabled(true);
    if (sources) {
      const totalAmount = transaction.amount / 100;

      const sourcesTotalAvailableAmount = sources.reduce((sum, source) => {
        sum += source[sourceAmountKey];
        return sum;
      }, 0);
      if (sourcesTotalAvailableAmount >= totalAmount) {
        setDisabled(false);
        return;
      }
    }
  }, [sources]);

  const submit = () => {
    if (someSourceHasNegativeAmount.current) {
      setShowNegativeAmountModal(true);
      return;
    }

    setSelected(selected + 1);
  };


  if (sources === false || propsLoading || !portfolio) return <LoadingBox />;

  const policyHolder = portfolio.policy_holder[0];

  if (!policyHolder) return <LoadingBox />;

  const isCompany = policyHolder.type === 'COMPANY';

  return (
    <>
      <Tutorial
        steps={tutorialSteps.current}
      />
      <Main id="tutorial-intro">
        {/* <SourceOfWealthInstructions isHighRisk={isHighRisk} />
        <SourceOfWealthHighRiskIntro isHighRisk={isHighRisk} /> */}
        <Modal open={showNegativeAmountModal}>
          <img className="input-max-width" src="/illustrations/kalla_till_kapital.svg" alt="Källa till kapital" />
          <Heading size="3">Skicka in bevis</Heading>
          <p>Försäkringsbolaget kräver att samtliga använda källor som ännu inte är bevisade och granskade behöver nu bevisas. Detta kommer även gälla framåt i tiden där en källa inte kan användas för insättning om den inte har bevisats och granskats.</p>
          <p>Kontakta oss gärna vid frågor så kan vi hjälpa er att komma vidare i processen.</p>
          {__('hubins_contact_information')}
          <ButtonWrap>
            <Button onClick={() => setShowNegativeAmountModal(false)}>Stäng</Button>
          </ButtonWrap>
        </Modal>
        <Columns>
          <Column m="6" l="7" xl="9">
            <Heading size="2">
            Var kommer dina pengar ifrån?
            </Heading>
            <Heading className="s-top-xl s-bottom-xl" size="4">Lägg till, uppdatera och bevisa källor</Heading>
            <ol className="s-bottom-xl s-top-m" id="tutorial-steps">
              <li>I detta steg kan du lägga till nya källor samt uppdatera befintliga källor och ladda upp bevis.</li>
              <li>För att kunna gå vidare behöver du ha ett sammanlagt tillgängligt belopp som överstiger insättningen. Notera att för vissa källor behöver bevis inlämnas och granskas innan beloppet blir tillgängligt.</li>
              <li>I nästa steg behöver du ange var just denna insättning kommer ifrån genom att välja en eller flera källor. Du kan endast välja de källor som har ett tillgängligt belopp.</li>
              <li>Klicka på “Gå vidare” via statusfältet nedan.</li>
            </ol>
          </Column>
          <Column m="6" l="5" xl="3" className="s-bottom-xl">
            <SourceOfFundsSummary sources={sources} isHighRisk={isHighRisk} />
          </Column>
          <SourceCards
            totalAmount={transaction.amount / 100}
            sources={sources}
            setSources={setSources}
            premium={true}
            portfolioUuid={transaction.portfolio_uuid}
            isHighRisk={isHighRisk}
            showAvailableAmount
            isCompany={isCompany}
          />
          <Column s="12" className="center padding s-top-l">
            <Button
              className="hidden"
              onClick={submit}
              innerRef={innerRef}
            >
            Gå vidare
            </Button>
          </Column>
        </Columns>
      </Main>
    </>
  );
};

export default SourceOfWealth;
