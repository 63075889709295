import React, {useState} from 'react';
import Heading from '@hubins/components/Heading';
import Icon from '../IconNew'
import { useCollapse } from 'react-collapsed';
import './index.scss';

const FAQRow = ({title, content}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className='faq-component__row'>
      <div className="faq-component__title" {...getToggleProps({ onClick: toggleExpand })}>
        <Heading size="4">{title}</Heading>
        <Icon icon={isExpanded ? "subtract" : "add"} size={24} />
      </div>
      <div className="faq-component__content" {...getCollapseProps()}>
        {content}
      </div>
    </div>
  )
}

export const FAQ = ({rows}) => {
  return (
    <div className='faq-component'>
      {rows.map((row, index) => {
        return (
          <FAQRow key={index} title={row.title} content={row.content} />
        )
      })}
    </div>
  )

};

export default FAQ;